@charset "utf-8";
/* font-family: 'PT Sans', sans-serif; */
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap");
@import url("bulma/css/bulma.css");
/* @import url("material-design-icons/iconfont/material-icons.css"); */
@import url("../fonts/stylesheet.css");

html {
  /* background-color: #212121; */
  touch-action: pan-down;
}

:root {
  --purple: #8656ab;
  --green: #60ccb8;
  --yellow: #ffbc37;
  --red: #e62c59;
  --blue: #2f9ac7;
  /* --dark-blue: #323593; */
  --dark-blue: rgba(50, 53, 147, 80%);
  --gray: #869290;
  --gray-1: #A1A1A1;
  --black: #3E3E3E;
  --light-grey: #F1F1F1
}

.default-bg {
  background-color: #F1F1F1
    /* background-color: #df0f0f */

}


.purple {
  background-color: var(--purple);
  border-color: var(--purple) !important;
}

.purple_border {
  background: transparent;
  border-color: var(--purple) !important;
  color: var(--purple) !important;
}

.green {
  background-color: var(--green);
  border-color: var(--green) !important;
}

.green_border {
  background: transparent;
  border-color: var(--green) !important;
  color: var(--green) !important;
}

.yellow {
  background-color: var(--yellow);
  border-color: var(--yellow) !important;
}

.yellow_border {
  background: transparent;
  border-color: var(--yellow) !important;
  color: var(--yellow) !important;
}

.red {
  background-color: var(--red);
  border-color: var(--red) !important;
}

.red_border {
  background: transparent;
  border-color: var(--red) !important;
  color: var(--red) !important;
}

.blue {
  background-color: var(--blue);
  border-color: var(--blue) !important;
}

.blue_border {
  background: transparent;
  border-color: var(--blue) !important;
  color: var(--blue) !important;
}

.gray {
  background-color: var(--gray);
  border-color: var(--gray) !important;
}

.gray_border {
  background: transparent;
  border-color: var(--gray) !important;
  color: var(--gray) !important;
}

li {
  list-style: none;
}

body {
  /* font-family: "PT Sans", sans-serif; */
  font-family: "Montserrat";
  font-family: 'Montserrat' !important;
  scroll-behavior: smooth
    /* background: #f5f6f7; */
}

body.modal-open {
  overflow: hidden;
  height: 100vh;
}

p {
  /* color: var(--black) !important; */
  font-size: 14px !important;
  line-height: 1.5 !important;
  font-family: "Montserrat";
  font-weight: 500;
}

small {
  color: var(--black);
}

* {
  box-sizing: border-box;
}

/*common*/
.btn.blue-btn {
  background: #00022e;
  color: #fff;
  padding: 5px 35px;
}

input,
button,
select,
input:focus,
button:focus,
select:focus,
input:active,
button:active,
select:active,
input.form-control,
input.form-control:focus,
input.form-control:active,
textarea.form-control,
textarea.form-control:focus,
textarea.form-control:active {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  box-shadow: none;
}

::placeholder {
  color: #c4c4c4;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c4c4c4;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c4c4c4;
}

.circle-img img {
  width: 90%;
}

img {
  max-width: 100%;
}

.wrapper {
  max-width: 767px;
  margin: auto;
  position: relative;
}

/*home*/
header {
  padding: 0px 0 0;
  overflow: hidden;
  /* -webkit-transition: 0.3s all;
  transition: 0.3s all; */
}

.cart_wrapper.custom_pb header .container {
  padding: 15px 0px 5px;
}

.px_15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

header.verify_header {
  padding: 20px 0 10px;
}

header.verify_header .left_col {
  display: flex;
  align-items: center;
}

header.verify_header .notify .notification {
  margin-bottom: 0;
}

.powered_logo {
  width: 54px;
  display: inline-block;
  margin: 0 0 5px 5px;
}

.circle-img {
  padding: 0 0;
  align-self: center;
}

.disable .circle-img,
.disable .naming-sec {
  display: none;
}

.profile-row.disable {
  justify-content: flex-end;
}

.circle-img-lg {
  background: url(../Assets/images/image_circle.svg) no-repeat center center / 100% auto;
  height: 105px;
  padding: 0;
  border-radius: 50%;
}

.circle-img-lg figure {
  display: flex;
  margin: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.naming-sec h3 {
  font-size: 25px;
  color: #3e3e3e;
}

.naming-sec p {
  font-size: 17px !important;
  color: var(--black) !important;
}

.circle-img figure {
  border: 1px solid #bbb;
  border-radius: 50%;
  margin: 0;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  overflow: hidden;
}

.circle-img.default figure {
  height: 20vw;
  width: 20vw;
  margin: auto;
}

.naming-sec h4 {
  font-weight: 600;
  font-size: 18px;
  color: var(--black);
  margin: 0;
}

.naming-sec-perk p {
  font-weight: 500;
  font-size: 14px;
  color: var(--black);
  margin: 0;
}

.naming-sec-perk h4 {
  font-weight: 600;
  font-size: 15px;
  color: var(--black);
  margin: 0;
}

.naming-sec p {
  font-size: 13px;
}

header .container {
  padding: 15px 0px 15px;
  width: 100% !important;
  background: white;
}


header.hideClass {
  display: none;
}

.naming-sec p {
  margin: 0;
  color: #3d3e3d;
  font-size: 14px !important;
  font-weight: 500;
}

.naming-more {
  width: fit-content;
  border: 1.5px solid var(--store-color);
  color: var(--store-color);
  border-radius: 10px;
}

.search-bar.input-text {
  background: #fff url(../Assets/images/search_icon.svg) no-repeat 15px 50% / 18px auto;
  border: 1px solid #bababc;
  margin: 8px auto;
  border-radius: 5px;
  height: 45px;
  padding: 0 15px 0 45px;
  width: 100%;
  font-size: 14px;
  color: #3d3d3d;
}

.notification {
  display: block;
  width: 25px;
  height: 25px;
  /* background: url(../Assets/images/bi_cart.svg) no-repeat center center; */
  background-size: contain;
  position: relative;
  padding: 0;
  margin: 0;
  margin-right: 20px;
  margin-bottom: 5px !important;
}

.profile {
  display: block;
  width: 30px;
  height: 30px;
  background: var(--store-color) url(../Assets/images/user.svg) no-repeat center center / 18px auto;
  border-radius: 50%;
}

.notify {
  display: flex;
  justify-content: flex-end;
  /* justify-content: space-between; */
}

.counts {
  position: absolute;
  left: 295px;
  top: 23px;
  background: #d65b4c;
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 1.5;
  border: 2px solid #f5f6f7;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.counts.cart-count {
  left: 100px;
  top: 12px;

}

.link-box {
  padding: 0 5px 15px;
  position: relative;
}

.link-box.active:after {
  /* content: ""; */
  display: inline-block;
  width: 65%;
  height: 5px;
  background: var(--store-color);
  bottom: 10px;
  position: absolute;
}

.link-box a,
.link-box .box {
  display: flex;
  height: 85px;
  width: 95px;
  background: #fff;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  text-transform: uppercase;
  padding: 0 5px;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  margin: auto;
  /* -webkit-transition: 0.1s all;
  transition: 0.1s all; */
  text-align: center;
  line-height: normal;
}

.link-box .blue {
  background: #2793c2;
}

.link-box .purple {
  background: #9359a5;
}

.link-box .yellow {
  background: #f6bf1e;
}

.link-box .green {
  background: #63c6b4;
}

.link-box .pink {
  background: #d92a93;
}

.menu-row {
  background-color: white;
  flex-wrap: nowrap;
  padding: 0 10px;
  overflow: auto;
  /* margin-bottom: 19px; */
}

.scroll_active .menu-row {
  /* margin-left: 10px !important; */
}

.menu-box {
  margin: 0;
  /* background: rgb(255, 0, 0); */
  background: #fff;
  padding: 12px;
  position: relative;
}


.menu-box.disabled,
.options.disabled {
  opacity: 0.2;
}

.menu-box a {
  text-decoration: none;
}

.menu-box::after {
  content: "";
  border-bottom: 2px solid #e8e8e8;
  width: calc(100% - 32px);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.menu-box:last-child::after {
  display: none;
}

.menu-img {
  padding: 0;
  margin: 0;
}

.menu-img img {
  height: 80px;
  width: 100%;
}

.menu-content {
  padding-right: 0;
  /* padding-bottom: 5px; */
  position: static;
  padding-left: 12px;
  /* margin-top: 10px; */
}

.menu-content h4 {
  margin-top: -2px !important;
  font-weight: 600;
  font-size: 15px;
  color: var(--black);
  /* margin: 0 auto 5px; */
  margin: 0px 0 4px;
  max-width: 100%;
}

.txt-green {
  color: var(--store-color);
}

.menu-content p {
  color: var(--black);
  font-size: 13px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  margin-bottom: 0px;
  line-height: normal;
  /* font-style: italic; */
}

.menu-content p.pricing {
  font-size: 15px;
  color: var(--black);
  font-weight: 700;
}

.menu-content p.pricing.strike-off {
  font-size: 16px;
  text-decoration: line-through;
}

.menu-content p.promotion-price {
  font-size: 15px;
  font-weight: 600;
  color: var(--store-hyperlink-color);
}

.promotion-price small {
  color: var(--store-hyperlink-color);

}

/* .menu-box.active::before {
  content: "";
  width: 8px;
  height: calc(100% - 20px);
  position: absolute;
  left: 0;
  top: 10px;
  background: var(--store-color) 

} */
/* .menu-box.active::before {
 content: "25";
    width: 25px;
    height: 25px;
    position: absolute;
    left: 56px;
    top: 10px;
    background: var(--store-color);
    background: red;
    border-radius: 50%;
    z-index: 9999999;
    text-align: center;
} */

.menu-box.submitted.active::before {
  background: #bababa;
}

.menu-sections .title {
  font-weight: 600;
  font-size: 18px;
  padding: 25px 15px 5px 12px;
  margin: 0;
  text-transform: capitalize;
  background: white;
}

.menu-sections {
  /* height: calc(100vh - 240px); */
  overflow-y: auto;
  /* -webkit-transition: 0.3s all;
  transition: 0.3s all; */
  padding-bottom: 80px !important;
  background: var(--light-grey) !important;
}

.bottom-sec {
  color: #fff;
  padding: 15px 0px 15px 0px;
  position: fixed;
  width: 100%;
  bottom: 0px;
  /* background: #fff; */
  max-width: 767px;
  z-index: 999
}

.bottom-sec-add-item {
  color: #fff;
  padding-bottom: 15px;
  position: fixed;
  width: 100%;
  bottom: 0px;
  /* background: #fff; */
  max-width: 767px;
  z-index: 999
}

.bottom-sec.center-btn {
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 767px;
  padding-top: 0;
}

.bottom-sec.flex_btns {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.bottom-sec.flex_btns .adding-items {
  max-width: 100%;
  flex: 0 0 100%;
}

.bottom-sec.flex_btns .btns {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
}

.bottom-sec.flex_btns .btns.duo {
  padding: 0 10px;
}

.bottom-sec.flex_btns .btns button {
  width: 100%;
  margin: 15px auto 0;
}

.bottom-sec.flex_btns .btns .back_btn {
  max-width: 100px;
  padding: 10px 0;
  font-size: 20px;
}

.bottom-sec.flex_btns .btns .add_btn {
  max-width: calc(100% - 120px);
}

.bottom-sec .green-sec,
.pink-btn {
  margin: 0;
  display: flex;
  justify-content: space-between;
  background: var(--store-color);
  padding: 3px 5px;
  border-radius: 28px;
  align-items: center;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
}

.bottom-sec .green-sec strong,
.pink-btn strong {
  color: #fff;
  font-weight: 600;
}

.items-count {
  display: inline-block;
  width: 28px;
  height: 28px;
  background: var(--store-color);
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  font-size: 18px;
}

.green-sec .total-price strong {
  font-size: 23px;
  display: block;
  margin-bottom: -8px;
  flex: 0 0 100%;
}

.green-sec .total-price strong small {
  font-weight: bold;
  font-size: 70%;
}

.total-price {
  text-transform: uppercase;
  text-align: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  top: -2px;
}

.green-sec strong,
.pink-btn strong {
  font-size: 20px;
}

.recommended_slider {
  padding-right: 25px;
}

.welcome-sec {
  align-items: baseline;
  margin-top: -35px;
  align-items: flex-end;
}

.welcome-sec p {
  color: #858585;
}

.ratings-sec p {
  margin: 0 auto 8px;
}

.ratings-sec {
  width: 100%;
  padding: 10px 0;
}

.ratings-sec .ratings {
  border: 1px solid #bababc;
  padding: 6px 40px;
  border-radius: 40px;
  background: #fff;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.ratings .star {
  display: inline-block;
  width: 35px;
  height: 35px;
  background: url(../Assets/images/star.svg) no-repeat center center;
}

/*Add items sec*/
.image-sec figure {
  margin: 0;
}

.item-details {
  background: #F1F1F1;
  /* margin-top: -63px; */
  position: relative;
  /* border-radius: 30px 30px 0 0; */
  padding: 0px 0;
  overflow: hidden;
}

.item-details.marginTop {
  margin-top: 0px !important;

}

.product-details {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  background: #fff;
  padding: 32px 20px 18px;
  align-items: center;
}

.item-tags {
  font-size: 13px;
  font-weight: 600;
  color: #9A9A9A;
}

.item-details .selectors .options {
  padding: 12px 0 12px;
  border-bottom: 1px solid #ddd;
}

.item-details .selectors .options:last-child {
  border: 0;
}

/* .item-details .selectors .options:nth-child(1) {
  padding-top: 5px;
} */
.item-details .selectors h5.sm-title.pl-1 {
  /* padding-bottom: 4px; */
  padding-left: 0 !important;
}

.product-details p {
  margin: 10px auto 0;
}

.product-details .name,
.product-details .price {
  padding: 0;
  margin: 0;
}

.product-details .price {
  text-align: right;
  font-size: 18px;
}

.sm-title {
  margin: 0;
  padding: 8px 12px;
  font-weight: bold;
  color: var(--black);
  font-family: "Montserrat";
  font-size: 16px !important;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.product-details .name {
  font-weight: bold;
  font-size: 18px;
  color: #3d3d3d;
}

.options label:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 5px;
  border: 2px solid #bababc;
  margin-right: 15px;
}

.options input.check:checked+label:before {
  background: #ccc;
}

.options label {
  display: flex;
  align-items: center;
  color: #595556;
  margin: 0;
}

input.check {
  display: none;
}

.selectors {
  padding: 8px 12px 20px;
  background: #fff;
}

.options {
  display: flex;
  padding: 15px 0;
  justify-content: space-between;
}

.price-sm {
  color: #595556;
  font-size: 14px;
}

.items-sec {
  display: flex;
  align-items: center;
}

.add-item-count {
  border: 2px solid white;
  border-radius: 23px;
  padding-top: 2px;
  padding-bottom: 2px;

}

.remove-btn,
.add-btn {
  padding: 0px;
  display: flex;
  /* width: 26px; */
  /* height: 26px; */
  border: none;
  /* background: #fff; */
  /* border-radius: 50%; */
  /* border: 1.5px solid var(--store-hyperlink-color); */
}




.items-num {
  border: 0;
  appearance: none;
  -webkit-appearance: none;
  width: 45px;
  height: 40px;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  color: #535353;
}

.adding-items {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 0px;
  /* background: #fff; */
  margin: 0px auto 0;
}

.price-sec p {
  margin: -5px 0 0 0;
  text-transform: uppercase;
}

.price-sec strong {
  font-size: 22px;
  color: var(--store-color);
}

.price-sec {
  text-align: right;
}

/* .add-btn {
  background: url(../Assets/images/plus.svg) no-repeat center center / 14px;
}

.remove-btn {
  background: url(../Assets/images/minus.svg) no-repeat center center / 14px;
} */

.green-btn {
  background: var(--store-color) !important;
  border: 2px solid transparent;
  color: #fff;
  border-radius: 23px;
  height: auto;
  width: 100%;
  font-size: 20px;

  font-family: "Montserrat";
  font-weight: 600;
}

.green-btn.redeemBtn {
  height: 50px;
}

.bottom-sec .green-btn {
  max-width: calc(100% - 30px);
  margin: 12px auto 0;
  display: block;
  padding: 10px 0;
  font-size: 20px;
  box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.08);
}

.bottom-sec .green-sec,
.pink-btn {
  max-width: calc(100% - 30px);
  margin: 0px auto;
  z-index: 99999;
  height: 56px;
  box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.08);
}

.green-border-btn {
  background: transparent;
  border: 2px solid var(--store-color);
  color: var(--store-color);
  /*height: 65px;*/
  height: auto;
  font-size: 20px;
  font-weight: bold;
  border-radius: 7px;
  padding: 10px 0 !important;
}

.bottom-sec.flex_btns .green-border-btn {
  margin-right: 20px;
  padding: 0 20px;
}

.cross-btn,
.cross-icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  position: absolute;
  z-index: 1;
  background: url(../Assets/images/cross_wht.svg) no-repeat center center;
  right: 15px;
  top: 25px;
  cursor: pointer;
  /* box-shadow: 0 0 5px rgb(0 0 0 / 12%); */
  border-radius: 50%;
}


/*cart page*/
.hotel_name {
  /* justify-content: center; */
  /* margin: 20px auto 0; */
  align-items: center;
}

.hotel_name .naming-sec p {
  color: #858585;
  text-align: left;
}

.back,
a.back,
a.back:hover {
  padding-left: 5px;
  color: var(--store-color);
  font-weight: bold;
  /* background: url(../Assets/images/left_arrow_green.svg) no-repeat center left / 8px; */
  text-decoration: none;
  font-size: 17px;
}

h5.green-title {
  color: var(--store-color);
  font-weight: 600;
  font-size: 14px;
  margin: 0;
}

h5.green-title.grey {
  color: #858585;
}

.cart-sec-ms .menu-content h4 {
  font-size: 15px;
  margin-bottom: 0;
}

.other-options {
  padding: 0 0 0 25px;
  line-height: normal;
  margin: 0;
}

.other-options li {
  font-size: 14px;
}

.pricing-lg {
  color: var(--black);
  font-weight: 700;
  font-size: 15px;
  margin: 0;
  text-align: end;
}

.cart-sec-ms .menu-box {
  align-items: center;
  padding: 20px 15px;
}

.cart-sec-ms .menu-box .menu-img {
  padding: 0px 0px;
  overflow: hidden;
  width: 70px;
  height: 70px;
  margin-right: 10px;
}

.cart-sec-ms .menu-box img {
  /* height: 80px;
  width: 100%; */
}

.cart-sec-ms .menu-content h4 {
  margin: 0;
}

.cart-sec-ms .menu-box.added:before {
  content: "";
  width: 7px;
  height: calc(100% - 10px);
  position: absolute;
  background: #a5a5a5;
  left: 0;
  top: 5px;
  display: block !important;
}

.cart-sec-ms .react-draggable:last-child .menu-box:after {
  /* display: none; */
}

.billing-list {
  padding: 0px 15px;
  /* background: #fff; */
  /* margin: 10px auto; */
}

.billing-list li {
  padding: 5px 0px;
  display: flex;
  justify-content: space-between;
  color: #8e8e8e;
  align-items: center;
  height: 45px;
}

.billing-list strong {
  font-weight: normal;
}

.billing-list li.total {
  border-top: 3px solid #e8e8e8;
}

.billing-list li span {
  /* font-weight: bold; */
  color: var(--black);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
}

.billing-list.pay-detail li span {
  color: var(--black);
  font-size: 16px;
  text-transform: none;
}

.billing-list li strong {
  color: var(--black);
  font-size: 15px;
  font-weight: 700;
}

.billing-list .type-text {
  text-align: right;
  color: #b7b5b6;
  background: #fff;
  width: 80px;
  height: 35px;
  border: 1px solid #ccc;
  padding: 0 10px;
  font-weight: bold;
}

.billing-list li.total span {
  font-size: 17px;
  color: #5b5c5e;
}

.billing-list li.total strong {
  font-size: 15px;
  font-weight: 700;
}

.link,
a.link,
a.link:hover {
  font-size: 16px;
  font-weight: bold;
  color: var(--store-color);
  text-decoration: none;
  cursor: pointer;
}

.new_items .menu-box::before {
  content: "";
  width: 7px;
  height: calc(100% - 10px);
  position: absolute;
  background: #a5a5a5;
  left: 0;
  top: 5px;
}

.green-btn img {
  max-width: 40px;
  margin: auto;
}

.btn-sec a.green-btn,
.btn-sec a.green-btn:hover {
  text-align: center;
  color: #fff;
  text-decoration: none;
}

.varification-sec {
  background: #fff;
  z-index: 1;
  position: relative;
  /* border-radius: 20px 20px 0 0; */
  padding: 20px 0 0;
  display: flow-root;
}

.varification-sec.default {
  padding-top: 19vh;
}

.varification-sec .circle-img {
  margin: 0px auto;
}

.varification-sec .login-form h5.title {
  font-size: 26px;
}

.overlay {
  background: #f5f6f8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.cross-icon {
  background: url(../Assets/images/cross_gry.svg) no-repeat center center;
  left: auto;
  right: 15px;
}

.title {
  font-weight: bold;
}

.title-heading {
  color: #585555;
}

.num {
  width: 32px;
  height: 32px;
  background: #e8e8e8;
  text-align: center;
  border-radius: 50%;
  line-height: 1.8;
  font-weight: bold;
  margin-right: 13px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.input-text {
  border-radius: 10px;
  margin: 15px auto 6px;
  width: 100%;
  border: 1px solid #bababc;
  height: 45px;
  font-size: 14px;
  padding: 0 10px;
  background: white;
  font-family: "Montserrat";
}

.login-form .btn.green-btn {
  width: auto;
  font-size: 15px;
  width: 120px;
  display: table;
  margin: auto;
}

.or::before {
  content: "";
  display: inline-block;
  width: 100%;
  position: absolute;
  border-bottom: 2px solid #C2C2C2;
  left: 0;
  top: 50%;
}

.or span {
  color: var(--black);
  text-transform: uppercase;
  font-weight: bold;
  background: #fff;
  border-radius: 50%;
  position: relative;
  padding: 10px;
}

.or {
  position: relative;
  width: 80%;
  text-align: center;
  margin: 20px auto;
}

.qr-sec {
  width: 65%;
  margin: 20px auto 0;
}

.qr-sec figure {
  border: 2px dashed #dddee0;
  padding: 20px;
}

.qr-sec .btn-sec {
  padding: 15px 0;
}

.qr-sec .green-btn img {
  max-width: 30px;
  margin: 0 auto 5px;
}

.qr-sec .green-btn {
  padding: 10px 0px;
  font-size: 15px;
  height: auto;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.85) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.also-like-sec {
  margin: 0;
  padding: 10px 0px;
  background: #fff;
}

.also-like-sec .menu-img {
  width: 100%;
  height: 150px;
}

.also-like-sec img {
  /* max-width: 80px; */
  margin: 0 auto 0px;
}

.also-like-sec {
  text-align: center;
}

.sm-h5 {
  font-weight: 600;
  font-size: 14px;
  color: #595556;
  margin: 0;
  font-family: "Montserrat";
  text-align: start;
}

.also-like-sec .col-6 {
  /* padding: 0;
  margin: 5px 0; */
}

.loyality_ms .middle-sec {
  background: #fff;
  position: relative;
}

.info {
  text-align: center;
}

.info p {
  text-transform: uppercase;
  margin: 0;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.3;
  color: #9f9f9f;
  flex: 0 0 100%;
}

.member-info-title {
  font-size: 18px;
  font-weight: 600;
}

.info h4 {
  margin: 0;
  color: #5e5e60;
  font-weight: bold;
}

.account-info .title,
.fav-info .title,
.history-info .title {
  color: #3e3e3e;
  padding: 15px 15px 5px;
  margin: 0;
  font-size: 22px;
  width: 100%;
  background: #f5f6f7;
}

.info-sec h4.title,
.account-info .title,
.fav-info .title,
.history-info .title {
  background: #f5f6f7;
  padding: 0 15px 6px;
  font-size: 18px;
}

.title-loyality {
  background: #f5f6f7;
  /* padding: 0 15px 6px; */
  font-size: 18px;
  font-weight: 600;
}

.info .visits {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-end;
}

.border-right {
  border-right: 1px solid #e8e8e8 !important;
}

.info-sec,
.fav-sec {
  background: #fff;
  position: relative;
  /* margin: 7px auto 0; */
}

.info-details label,
#promotions label,
#vouchers label {
  color: #9f9f9f;
  /* font-weight: bold; */
  /* text-transform: uppercase; */
  font-size: 13px;
  margin: 0;
  font-family: "Montserrat";
}

.tag {
  display: inline-block;
  padding: 8px 20px;
  border-radius: 30px;
  background: #ccc;
  width: 100px;
  text-align: center;
  font-weight: bold;
}

.tag.green {
  background: #9cd8d8;
  color: #105f5a;
}

.info-details p {
  font-size: 15px;
  color: #575654;
}

.info-details>div {
  /* margin-top: 10px; */
  margin: 0px auto 8px;
}

.fav-sec .menu-content h4 {
  font-size: 14px;
  margin: 0;
}

.fav-sec .menu-content p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
}

.fav-sec .menu-content p.pricing {
  font-size: 15px;
}

.square-img p {
  margin: 0;
  color: #fff;
  flex: 0 0 100%;
  line-height: 1.3;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
}

.square-img {
  text-align: center;
  color: #fff;
  background: var(--store-color);
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  height: 52px;
  width: 52px;
  border-radius: 5px;
  float: right;
  font-size: 14px;
}

.square-img h5 {
  flex: 0 0 100%;
  margin: 0 auto 0px;
  font-weight: bold;
}

.history-info h2.pricing {
  color: #696a6c;
  font-weight: bold;
  text-align: right;
  font-size: 14px;
}

.history-info h2.pricing small {
  font-weight: bold;
  font-size: 60%;
}

.item-promotion-price {
  font-weight: 600;
  font-size: 15px;

}

.history-info p {
  color: var(--black);
  line-height: normal;
  font-weight: 600;
}

.history-info .naming-sec h4 {
  margin-bottom: 6px;
}

.more-btn {
  border: 0;
  display: inline-block;
  font-weight: bold;
  color: var(--store-color);
  /* background: url(../Assets/images/up-arrow-green.png) no-repeat 22px 8px / 12px; */
  background: white;
  font-size: 14px;
  float: right;
  /* padding: 0 0 0 40px; */
}

.scroll-divs {
  overflow: auto;
  width: 100%;
  max-width: none;
}

.more-btn.open {
  background: url(../Assets/images/down-arrow-green.png) no-repeat 10px 10px / 18px;
}

.btns .more-btn,
.btns .more-btn.open {
  background-position: 10px 20px;
  min-height: 45px;
}

.h5 {}

.bill-details {
  border-top: 2px solid #e8e8e8;
  max-width: calc(100% - 0px);
  margin: auto;
  margin: 15px auto 0;
  padding-top: 5px;
}

.bill-details ul {
  margin: 0;
  padding: 10px 0 0;
}

.bill-details li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 0;
}

.bill-details li label {
  font-weight: bold;
  color: var(--black);
  text-transform: uppercase;
  font-size: 13px;
  margin: 0;
}

.bill-details h4 {
  font-size: 16px;
  font-weight: bold;
  color: #535353;
  margin: 0;
}

.bill-details li.total label,
.bill-details li.total h4 {
  color: var(--store-color);
}


.bill-details li.total {
  border-top: 2px solid #e8e8e8;
}

.history_details,
.promotion_details,
.vouchers_details {
  padding: 10px 0;
  border-bottom: 2px solid #e8e8e8;
  /* max-width: calc(100% - 30px); */
  margin: auto;
}

.loyality_ms .link-box a,
.loyality_ms .link-box .box {
  aspect-ratio: auto 1 / 1;
  width: 100%;
}

.btns {
  justify-content: space-between;
}

.green-btn.btn-sm {
  font-size: 15px;
  text-transform: uppercase;
  height: 45px;
  width: 120px;
  border-radius: 5px;
}

#promotions .more-info p,
#vouchers .more-info p {
  font-size: 18px;
  margin: 0;
}

.promotion_details figure,
.vouchers_details figure {
  margin: 0;
}

.more-info .col-4 {
  margin-top: 15px;
}

.voucher-sec {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0;
  max-width: 80%;
  margin: 0 auto 30px;
}

.voucher-sec li {
  margin: 0 10px;
}

.voucher-sec li a {
  padding: 10px 15px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  color: #9f9f9f;
}

.voucher-sec li.active a {
  border-bottom: 6px solid var(--store-color);
}

/* For pdf */
.react-pdf__Page {
  margin-top: 10px;
}

.react-pdf__Page__textContent {
  /* border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px; */
  display: none;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 0px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  max-width: 600px !important;
  width: 100% !important;
}

/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}

.pdf-next-prev-btn {
  margin: 20px auto;
  text-align: center;
}

.pdf-next-prev-btn button {
  margin: 0 5px;
  background: transparent;
  border: 1px solid rgba(0 0 0 / 20%);
  color: #585858;
  font-size: 14px;
  padding: 4px 13px;
}

/* loading-overlay */
.loading-overlay {
  display: flex;
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9998;
  align-items: center;
  justify-content: center;
}

/* page not found */
.error-template {
  padding: 40px 15px;
  text-align: center;
}

.error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
}

.error-actions .btn {
  margin-right: 10px;
}

.pdf_container .container div {
  height: auto !important;
}

.pdf_container .not-found-menu {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0 15px;
}

.pdf_container .not-found-menu h1 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.pdf_container .not-found-menu .error-details {
  line-height: 20px;
}

.pdf_container .not-found-menu figure {
  height: 60px;
  width: 60px;
  border: 2px solid #bbb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  overflow: hidden;
  margin-bottom: 20px;
}

.error_container .container div {
  height: auto !important;
}

.error_container .not-found-menu {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0 15px;
}

.error_container .not-found-menu {
  min-height: calc(100vh - 58px);
}

.error_container .not-found-menu h1 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.error_container .not-found-menu .error-details {
  line-height: 20px;
}

.error_container .not-found-menu figure {
  height: 60px;
  width: 60px;
  border: 2px solid #bbb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  overflow: hidden;
  margin-bottom: 20px;
}

.scroll-active header {
  /* padding-top: 5px !important; */
  /* opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  height: 0; */
  /* line-height: 0; */
}

.scroll_active .middle-sec .menu-row .col a {
  /* min-height: 40px; */
  height: auto;
  /* background: transparent !important; */
  /* color: #1ebdc3; */
  width: max-content;
  padding: 0 7px;
}

.menu-row a,
.menu-row a:focus,
.menu-row a:hover {
  text-decoration: none;
}

.scroll_active .middle-sec .menu-row .col a {
  width: max-content;
  padding: 0 7px;
}

.scroll_active .middle-sec .menu-row .col.active a {
  /* background: #1ebdc3 !important; */
  color: #fff;
  /* -webkit-transition: 0.05s all;
  transition: 0.05s all; */
}

.scroll_active .middle-sec .menu-row .col.active:after {
  display: none;
}

.scroll_active .middle-sec .menu-row .link-box {
  padding: 5px 5px 5px !important;
  flex: 0;
}

.scroll_active .menu-sections {
  /* height: calc(100vh - 125px); */
  /* max-height: calc(100vh - 250px); */
  /* -webkit-transition: 0.3s all;
  transition: 0.3s all; */
}

/* 20-aug-21 */

/* start labels */

.add-items_sec .options label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--black);
}

.add-items_sec .options label:before {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  /* border-color: #1ebdc3; */
  border-color: var(--store-color) !important
}

.add-items_sec .options input:checked+label:before {
  background: transparent;

}


.add-items_sec .options input:checked+label:after {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  /* background-color: #1ebdc3; */

  display: block;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 5px;
  border-radius: 3px;
  background-color: var(--store-color) !important
}

/* end of labels */

.add-items_sec {
  position: relative;
  height: 100vh;
}

.add-items_sec .image-sec,
.add-items_sec .image-sec .carousel-root,
.add-items_sec .image-sec .carousel-slider,
.add-items_sec .image-sec .slider-wrapper,
.add-items_sec .image-sec .slider-wrapper .slider {
  height: 100%;
}

.add-items_sec .image-sec figure {
  filter: brightness(0.5);
  background: #003e40;
}

.add-items_sec .image-sec img {
  width: 100%;
  height: 280px;
  object-fit: contain;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.add-items_sec .item-details {
  /* height: calc(100vh - 451px); */
  /* height: 100vh; */
  overflow: auto;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.add-items_sec .item-details .product-details .currency {
  font-size: 14px;
}

.add-items_sec .item-details .sm-title {
  font-size: 16px;
  /* margin: 12px 0px 0px 0px; */
}

.add-items_sec .item-details .product-details .description {
  text-align: left;
  margin-left: unset;
  width: 100%;
  margin-top: 6px;
}

.add-items_sec .item-details.top-scroll .product-details .description {
  padding-top: 35px;
}

.scroll-active .add-items_sec .item-details .product-details .description {
  margin-top: 10px;
  max-width: calc(100% - 50px);
}

.description {
  font-size: 16px;
  font-weight: 500;
}

.add-items_sec .image-sec .tags {
  position: absolute;
  top: 12px;
  right: 0;
  background-color: #3526a7;
  color: #fff;
  padding: 4px 13px;
  width: 200px;
  font-weight: bold;
  letter-spacing: 0.5px;
  z-index: 2;
}

.add-items_sec .image-sec .tags.blue {
  background-color: var(--store-color);
}

.add-items_sec .item-details .product-details .labels {
  text-align: right;
  margin-left: auto;
  margin-top: 32px;
}

.add-items_sec .item-details .product-details .labels ul {
  display: flex;
}

.add-items_sec .item-details .product-details .labels li {
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  cursor: default;
}

.add-items_sec .item-details .product-details .labels li:not(:last-child) {
  margin-right: 5px;
}

.add-items_sec .item-details .product-details .options {
  width: 100%;
  padding: 0;
}

.add-items_sec .item-details .product-details .options ul {
  width: 100%;
}

.add-items_sec .item-details .product-details .options li {
  width: 100%;
  display: flex;
  margin-top: 15px;
}

.add-items_sec .item-details .product-details .options li .price {
  margin-left: auto;
}

.add-items_sec .item-details .product-details .options label:before {
  border-radius: 50%;
}

.add-items_sec .item-details .product-details .options input:checked+label:after {
  border-radius: 50%;
}

/* cart wrapper */

.cart_wrapper .hotel_name {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* padding-top: 10px; */
}

.cart_wrapper .hotel_name .circle-img {
  margin-right: 20px;
}

.cart-sec-ms .other-options {
  padding: 0 0 0 0px;
  font-style: italic;
}

/* start home menus */

.menu-box .tag {
  position: absolute;
  right: 0;
  top: 0px;
  background-color: var(--dark-blue);
  border-radius: 0;
  text-align: left;
  color: #fff;
  justify-content: flex-start;
  z-index: 9;
  width: 70px;
  padding: 0 5px;
  font-size: 11px;
  z-index: 0;
}

.menu-box .label_row {
  display: flex;
  align-items: center;
  margin: 4px auto 0;
}

.menu-box .label_row ul {
  display: flex;
  margin-left: auto;
  position: absolute;
  right: 15px;
  bottom: 5px;
}

.menu-box .label_row ul li {
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  cursor: default;
}

.menu-box .label_row ul li:not(:last-child) {
  margin-right: 5px;
}

/* filter modal */

.filter_modal {
  border: 1px solid #b7bfbe;
  border-radius: 30px 30px 0 0;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  background: #fff;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  z-index: 99;
  width: 100%;
  max-width: 767px;
}

.filter_modal.show {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}

.filter_modal .modal_header {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #f2f2f2;
}

.filter_modal .modal_header .filter_icon {
  position: relative;
}

.filter_modal .modal_header .filter_icon .count {
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #e45445;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  bottom: -10px;
  left: 17px;
}

.filter_modal .modal_header .filter_icon img {
  width: 24px;
}

.filter_modal .modal_header h4 {
  color: #373b3b;
  font-size: 22px;
  font-weight: bold;
  margin-left: 25px;
}

.filter_modal .modal_header .close {
  margin-left: auto;
  opacity: 1;
}

.filter_modal .modal_header .close img {
  width: 30px;
  height: auto;
}

.filter_modal .modal_body {
  padding: 20px;
}

.filter_modal .modal_body .title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.filter_modal .modal_body ul {
  margin-left: -5px;
  margin-right: -5px;
}

.filter_modal .modal_body ul li {
  display: inline-block;
  margin: 5px;
  border-radius: 100px;
  padding: 7px 15px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  border: 2px solid #000;
}

.filter_modal .modal_body .apply_btn {
  margin: 0;
  display: flex;
  justify-content: center;
  background: var(--store-color);
  padding: 10px;
  border-radius: 5px;
  align-items: center;
  color: #fff;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  border: none;
  box-shadow: none;
  outline: none;
  margin-top: 20px;
}

.filter_modal .modal_body .apply_btn:disabled {
  background: #949494;
}

/* recommended section */

.recommended_section {
  padding: 0px 0px;
  overflow: hidden;
  background: #f3f3f3;
}

.recommended_section .title {
  font-size: 18px;
}

.recommended_slider {
  display: flex;
  overflow: auto;
  margin-left: 0px;
  margin-right: -10px;
}

.recommended_slider .swiper-slide {
  width: 50%;
  /* padding: 0 0 0 10px; */
}

.recommended_slider .swiper-slide .box {
  box-shadow: none;
  border-radius: 0px;
  padding: 10px 12px;
  border-left: 3px solid #d2d2d2;
}

.recommended_slider .swiper-slide:first-child .box {
  border-left: none;
}


.recommended_slider .swiper-slide .box .title_row {
  display: flex;
  width: 100%;
}

.recommended_slider .swiper-slide .box .title_row .item_title {
  font-size: 15px;
  font-weight: 600;
  width: calc(100% - 60px);
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.recommended_slider .swiper-slide .box .title_row .item_title span {
  color: var(--store-hyperlink-color);
}

/*.recommended_slider .swiper-slide .box .title_row .item_title text {
 font-size: 16px;
}*/
.recommended_slider .swiper-slide .box .title_row .price {
  margin-left: auto;
}

.recommended_slider .swiper-slide .box .date_row {
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: 100%;
}

.recommended_slider .swiper-slide .box .date_row .reorder {
  margin-left: auto;
  color: var(--store-color);
  font-weight: bold;
}

.recommended_slider .swiper-slide .box .date_row .reorder img {
  width: 18px;
  margin-right: 5px;
}

/* guest login */

.guest_login {
  text-align: center;
  margin-bottom: 30px;
}

.guest_login a {
  color: var(--store-color);
  font-weight: bold;
}

.menu-box .tag.blue {
  /* background: #2793c2; */
  background: rgba(39, 147, 194, 80%);
}

/* 23-aug */

.filter_row {
  /* display: flex; */
  align-items: center;
  margin-bottom: 0px;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 2;
  background: white;
}


.paddingHorizontal {
  padding-left: 15px;
  padding-right: 15px;

}

.filter_row .filter_icon {
  position: relative;
  cursor: pointer;
}

.filter_row .filter_icon .count {
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: #e45445;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  right: -14px;
  bottom: -11px;
}

.filter_row .filter_icon img {
  width: 24px;
}

.filter_row .search-bar {
  width: calc(100% - 45px);
  margin-right: 0;
}

/* menus row */

/* .menus_row .menu-row{width:calc(100% - 45px)} */
.menus_row .filter_icon {
  /* opacity: 0;
  visibility: hidden; */
  display: none;
}

.scroll_active .menus_row {
  /* padding: 0 15px; */
}

.scroll_active .menus_row .filter_icon {
  /* opacity: 1;
  visibility: visible;
  -webkit-transition: 0.2s all;
  transition: 0.2s all; */
  display: block;
  margin-top: -2px;
}

.scroll_active .menus_row .menu-row {
  /* width: calc(100% - 45px) !important; */
  /* padding: 0; */
  /* margin-left: 20px !important; */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.wrapper .menu-row::-webkit-scrollbar,
.wrapper .recommended_slider::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.wrapper .menu-row,
.wrapper .recommended_slider {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* scroll add-item page */

.scroll_active .add-items_sec .image-sec img {
  height: 110px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.scroll_active .add-items_sec .item-details {
  /* height: calc(100vh - 225px); */
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.menu-sections.new_items.red,
.menu-sections.new_items.red .menu-box {
  background: rgb(237 240 240);
  color: #fff;
}

/* .menu-sections.new_items.red .menu-box h4,
.menu-sections.new_items.red .menu-box .pricing-lg {
  color: #fff;
}

.menu-sections.new_items.red .menu-content h4 {
  color: #fff;
} */

.draggable_bg {
  background-color: #fff;
  position: relative;
}

.draggable_bg .delete_box {
  position: absolute;
  left: 0;
  top: 0px;
  bottom: 0px;
  /* height: 100%; */
  width: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  background-color: rgb(255 116 116);
}

.draggable_bg .delete_box img {
  width: 26px;
  filter: invert(1);
  margin-right: 7px;
}

.new_items_page .new_items {
  background-color: #fff;
}

.new_items_page .new_items .menu-box::before {
  display: none;
}

.new_items_page .menu-box:after {
  content: "";
  /* border-bottom: 1px solid #e8e8e8; */
  width: calc(100% - 32px);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: block !important;
  height: 1px;
  background-color: #e8e8e8 !important;
}

.new_items_page .draggable_bg:last-child .menu-box:after {
  display: none !important;
}

.recommended .title {
  font-weight: bold;
  font-size: 18px;
  padding: 10px 15px 10px;
  margin: 0;
  /* background: white; */
}

.outerScroll {
  background: #f5f6f7;
}

.billing-details {
  /* margin-top: 15px; */
}

/* welcome header */

header .welcome-sec {
  display: flex;
  margin: 0;
  align-items: center;
}

header .welcome-sec .circle-img-lg {
  height: auto;
  border: 1px solid #a5aeac;
  padding: 3px;
  background: unset;
}

header .welcome-sec .circle-img-lg .logo {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #4f5353;
}

header .welcome-sec .circle-img-lg .logo img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

header .welcome-sec .right_col {
  width: calc(100% - 88px);
  padding-left: 15px;
}

header .welcome-sec .right_col .notify .notification {
  margin-bottom: 0 !important;
}

header .welcome-sec .right_col .naming-sec {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

header .welcome-sec .right_col .naming-sec h3 {
  font-size: 20px;
  font-weight: 600;
}

.naming-sec .h3 {
  font-size: 20px;
}

.history-info p.h6 {
  font-size: 14px;
  margin: 0;
}

header .welcome-sec .right_col .naming-sec .powered_text {
  margin-top: 5px;
  font-size: 13px;
  display: flex;
  align-items: center;
}

/* .resend_otp{font-size:13px;} */
.login-form .resend_row {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.login-form .resend_row .green-btn {
  margin: 0 0 0 5px;
}

.custom_pb {
  padding-bottom: 92px;
  /* min-height: 100vh; */
}

.custom_pb .bottom-sec {
  position: fixed;
  /* position: sticky; */
  bottom: 0;
  width: 100%;
  /* background-color: #f5f6f7; */
  box-shadow: none;
  outline: none;
  border: none;
  /* border: 1px solid #f5f6f7; */
  max-width: 767px;
  z-index: 1;
  /* left: 0; right:0;
  margin: 0 auto; */
}

.desc-sec-top {
  margin: 0 auto;
}

.bottom-sec.disabled .green-sec,
.green-btn.disabled {
  background-color: var(--store-color);
}

.bottom-sec.disabled .items-count {
  background: #6eb5b7;
}

/* account modal */

.accountModal,
.promoModal,
.qrcode_modal,
.phone_agreement_modal {
  position: fixed;
  top: 100%;
  background: #f5f6f7;
  overflow: auto;
  width: 100%;
  border-radius: 30px 30px 0 0;
  bottom: 0;
  transform: translateY(100%);
  transition: 0.3s all;
  max-width: 767px;
}

.accountModal.show {
  transform: translateY(0);
  transition: 0.3s all;
  top: 62px;
  z-index: 9999;
}

.promoModal.show {
  transform: translateY(0);
  transition: 0.3s all;
  top: 45%;
  z-index: 9999;
}

/* qrcode modal */

.qrcode_modal {
  top: unset;
}

.qrcode_modal.show {
  transform: translateY(0);
  left: 0;
  top: 40% !important;
}

.qrcode_modal .top {
  padding: 15px 0;
}

.qrcode_modal .top h4 {
  text-align: center;
  font-size: 18px;
}

.qrcode_modal .screen {
  height: calc(100vh - 340px);
  background: antiquewhite;
  position: relative;
}

.qrcode_modal .screen .msg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  bottom: 20px;
  background-color: #000;
  color: #fff;
  border-radius: 100px;
  padding: 0px 10px;
  width: max-content;
  font-size: 12px;
}

.qrcode_modal .bottom-sec {
  padding: 10px;
}

.qrcode_modal .bottom-sec .green-sec {
  text-align: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.02rem;
  height: 50px;
}

/* phone agreement modal */

.phone_agreement_modal {
  top: unset;
  max-height: calc(100% - 90px);
  padding: 5%;
}

.phone_agreement_modal.show {
  transform: translateY(0);
}

.phone_agreement_modal .top {
  padding: 15px 0;
}

.phone_agreement_modal .top h4 {
  text-align: center;
  font-size: 18px;
}

.phone_agreement_modal .screen {
  height: calc(100vh - 340px);
  background: antiquewhite;
  position: relative;
}

.phone_agreement_modal .screen .msg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  bottom: 20px;
  background-color: #000;
  color: #fff;
  border-radius: 100px;
  padding: 0px 10px;
  width: max-content;

  font-size: 12px;
}

.phone_agreement_modal .bottom-sec {
  padding: 10px;
  display: flex;
}

.phone_agreement_modal .bottom-sec .green-sec {
  text-align: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.02rem;
  height: 50px;
  width: 45%;
}

.phone_agreement_modal .bottom-sec .green-sec.red {
  background: #e4222c;
}

/* star rating */
.rating_row .rating_outer {
  /* border: 1px solid #bababc; */
  border-radius: 100px;
  width: calc(100% - 30px);
  margin: 5px auto;
  background-color: #fff;
  padding: 5px;
}

.rating_row .inner_ratings {
  display: flex !important;
  justify-content: space-evenly;
}

.rating_row .inner_ratings .fa {
  color: var(--store-color);
}

/* custom modal */

.modal_comp {
  display: none;
  position: fixed;
  top: 0;
  margin: auto;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 2;
  left: 0;
  background: rgb(0, 0, 0, 0.5);
}

.modal_comp.show {
  display: block;
  max-width: 767px;
}

.modal_comp.show .modal {
  display: block !important;
}

.modal_comp .modal {
  position: absolute;
  width: calc(100% - 30px);
  margin: 0 auto;
  height: auto;
  bottom: unset;
  overflow: visible;
  transition: 0.2s all;
  -webkit-transition: 0.2s all;
  border-radius: 5px;
  background-color: #fff;
}

.modal_comp.show .modal {
  top: calc(50% - 58px);
  transform: translateY(-50%);
  transition: 0.2s all;
  -webkit-transition: 0.2s all;
}

.modal_comp .modal .close {
  position: absolute;
  right: 0;
  top: -35px;
  color: #fff;
  opacity: 1;
  font-weight: normal;
  font-size: 35px;
  cursor: pointer;
}

.modal_comp .modal .close:hover {
  opacity: 1;
}

.modal_comp .modal .modal_header,
.modal_comp .modal .modal_body,
.modal_comp .modal .modal_footer {
  padding: 15px 20px;
}

.modal_comp .modal .modal_body {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
}

.backdrop {
  position: fixed;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
}

body #root {
  /* max-height: calc(100vh - 58px); */
  /* overflow: auto; */
}

body #root.modal_active {
  overflow: hidden;
}

.modal_comp .modal .modal_footer {
  border-top: 1px solid rgba(0 0 0 / 10%);
  text-align: right;
}

.modal_comp .modal_footer .btn {
  padding: 5px 15px;
  font-size: 14px;
  margin-right: 5px;
}

.foobar-header h5 {
  font-size: 18px;
  font-weight: bold;
}

.resend_row {
  display: inline-block !important;
  height: auto;
  width: 100%;
}

.resend_row button.btn.green-btn {
  float: right;
}

.foobar-header {
  z-index: 9;
  position: relative;
  background: #fff;
  overflow: hidden;
  opacity: 0;
}

.foobar-header.touch-top {
  /* padding: 25px 20px 25px 50px; */
  padding: 10px;
  opacity: 1;
  z-index: 10;
}

.foobar-header.touch-top .cross-btn {
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);
}

.foobar-header.touch-top .cross-btn {
  top: 23px;
}

.cross-btn-hideaImage {
  display: inline-block;
  width: 24px;
  height: 24px;
  position: absolute;
  z-index: 1;
  background: url(../Assets/images/cross_wht.svg) no-repeat center center;
  right: 15px;
  /* top: 7px; */
  cursor: pointer;
  /* box-shadow: 0 0 5px rgb(0 0 0 / 12%); */
  border-radius: 50%;
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);

}


.add-items_sec .item-details.top-scroll {
  height: calc(100vh - 264px);
  z-index: 9;
}

.foobar-header p.description {
  overflow: auto;
  height: 100%;
}

.foobar-header.touch-top .description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
}

.edit-btn {
  background: var(--store-color);
}

.delete-btn {
  background: #d4594c;
}

.cart-sec-ms .menu-box {
  overflow: hidden;
}

.orderMenu {
  width: 100%;
  align-items: center;
  position: relative;
  z-index: 1;
  background: #fff;
  padding: 0px 0px;
}

.draggable_bg .delete_box {
  background: none;
  left: auto;
  right: 0;
  width: 180px;
}

.draggable_bg .delete_box:after {
  content: "";
  width: 100%;
  right: 15px;
  bottom: 0;
  position: absolute;
  border-bottom: 1px solid #e8e8e8;
}

.edit_delete {
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translate(0, -50%);
}

.edit_delete a:hover,
.edit_delete a:focus {
  color: #fff;
}

/* 08/10 */

.add-items_sec .carousel .slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.add-items_sec .carousel .slide div {
  height: 100%;
}

.add-items_sec .carousel .control-arrow {
  opacity: 1 !important;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff !important;
  border-radius: 50%;
  top: calc(50% - 30px) !important;
  transform: translateY(-50%);
}

.add-items_sec .carousel .control-arrow:before {
  opacity: 0.9;
}

.add-items_sec .carousel .control-arrow.control-prev {
  left: 10px;
}

.add-items_sec .carousel .control-arrow.control-next {
  right: 10px;
}

.add-items_sec .carousel .control-disabled.control-arrow {
  opacity: 0 !important;
}

.add-items_sec #ReactSimpleImageViewer {
  z-index: 9999;
}

.add-items_sec #ReactSimpleImageViewer .react-simple-image-viewer__modal-content {
  width: 100%;
}

.add-items_sec .react-simple-image-viewer__slide img {
  width: 100%;
  object-fit: cover;
}

.add-items_sec #ReactSimpleImageViewer .react-simple-image-viewer__next,
.add-items_sec #ReactSimpleImageViewer .react-simple-image-viewer__previous {
  opacity: 1;
  font-size: 22px;
  font-weight: 400;
  padding: 0 10px;
  display: none;
}

.add-items_sec #ReactSimpleImageViewer .react-simple-image-viewer__close {
  opacity: 1 !important;
}

#root.ios-device .menu-sections {
  /* height: calc(100vh - 270px); */
}

#root.ios-device .scroll_active .menu-sections {
  /* height: calc(100vh - 24px); */
}

#root.ios-device .add-items_sec .item-details {
  /* height: calc(100vh - 370px); */
}

/* .carousel .slider-wrapper {
  position: sticky;
  top: 0;
} */
#root.ios-device .add-items_sec .item-details.top-scroll {
  height: calc(100vh);
  /* position: absolute;
  top: 60px;
  transition: all 0.3s;
  width: 100%; */
}

/* #root.ios-device .add-items_sec .item-details {
  position: relative;
  transition: all 0.3s;
}

.foobar-header {
  position: absolute;
  top: 0;
  height: auto !important;
  width: 100%;
}
body #root.ios-device {
  height: 100vh;
} */
.menu-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 82px;
  width: 82px;
  /* min-width: 82px; */
  /* max-width:75px; */
  border-radius: 4px;
  overflow: hidden;
  padding: 0 !important;
}

.menu-img-space {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 82px; */
  width: 82px;
}

.menu-img img {
  height: 100%;
  object-fit: cover;
}

.image-container-sec {
  overflow: hidden;
}

body #root.ios-device {
  max-height: unset;
  /* max-height: calc(100vh - 108px); */

  /* min-height: 108vh; */
}

/* body #root.ios-device:after {
  content: "";
  display: inline-block;
  bottom: -80px;
  position: fixed;
  width: 100%;
  height: 100px;
  background: #ccc;
} */
/* body #root.ios-device .custom_pb .bottom-sec {
  top: 80vh;
  padding: 6px 0px;
}
body #root.ios-device .bottom-sec {
  top: 65vh;
} */
/* body #root.ios-device .custom_pb .bottom-sec { top: 84vh;}
body #root.ios-device .bottom-sec { top: 74vh;} */
body #root.ios-device .billing-details {
  padding-bottom: 100px;
}

body #root .billing-details {
  padding-bottom: 80px;
}

body #root.ios-device .custom_pb {
  padding-bottom: 0;
  /* height: 100vh;
   */
  /* height: 100%;
   min-height: 100vh; */
}

.add-items_sec .item-details .message_col {
  padding: 10px 12px 20px;
  background-color: #fff;
  margin: 0;
}

.add-items_sec .item-details .message_col label {
  color: #5b5b5d;
  font-weight: bold;
  font-size: 16px;
}

.add-items_sec .item-details .message_col textarea {
  height: 100px;
  border: none;
  padding: 0;
  resize: none;
  font-size: 16px;
  font-family: "Montserrat";
}

/* 18/oct */

.cart_sidebar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff !important;
  z-index: 9;
  /* left:0;right:0;margin:0 auto; */
}

.cart_sidebar header {
  background-color: rgb(245, 246, 247);
}

.outerScroll {
  max-height: calc(100vh - 180px);
  overflow: auto;
  position: relative;
  height: 100vh;
}

.outerScroll.scrollup {
  top: 48px;
}

/* .info-sec h4.title {
  font-size: 22px;
} */

.menu-sections .last-menu-items {
  /* min-height: calc(100vh - 220px); */
}

body #root.ios-device .last-menu-items {
  /* min-height: calc(100vh - 160px); */
}

.wrapper.scroll_active .menu-sections:last-child {
  /* padding-bottom: 80%; */
}

.cart-sec-ms .menu-box {
  min-height: 77px;
  padding: 13px 12px;
}

.orderMenu {
  min-height: 52px;
  height: 100%;
  justify-content: space-between;
}

.md-title.bold {
  font-size: 18px;
  font-weight: bold;
}

.desc-sec-top {
  text-align: center;
  padding: 15px 13px;
}

.desc-sec-top h4 {
  display: flex;
  font-weight: 600;
  font-size: 16px;
  margin: 0 auto 7px;
}

.varification-sec .custom-select {
  border-radius: 10px;
  margin: 20px auto;
  width: 100%;
  border: 1px solid #bababc;
  height: 45px;
  font-size: 14px;
  padding: 0 10px;
}

.acc-info-con button {
  background: #63c6b4;
  border-color: #63c6b4;
  color: #fff;
}

.acc-info-con button:focus,
.acc-info-con button:hover,
.acc-info-con button:active {
  background: #3fad99;
  border-color: #3fad99;
  color: #fff;
}

h5.sm-title span {
  font-weight: normal;
}

.menu-row .link-box>a.overlay-show:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.wrapper.scroll_active .menu-row .link-box>a.overlay-show:after {
  display: none;
}

.menu-row .link-box>a.overlay-show {
  position: relative;
}

.menu-row .link-box>a.overlay-show span {
  position: relative;
  z-index: 1;
}

.box.recomItem {
  display: flex;
  flex-wrap: wrap;
  /* align-content: flex-start; */
  justify-content: space-between;
  height: 100%;
}

.Toastify__toast {
  min-height: 0 !important;
}

.Toastify__toast-body {
  padding: 0 6px !important;
  margin: 0 !important;
}

.Toastify__toast-container--bottom-left,
.Toastify__toast-container--bottom-center,
.Toastify__toast-container--bottom-right,
.Toastify__toast-container--bottom-left,
.Toastify__toast-container--bottom-center,
.Toastify__toast-container--bottom-right {
  bottom: auto !important;
  top: 0 !important;
  -webkit-transform: translateX(0);
}

.Toastify__toast--success {
  background: var(--store-color) !important;
}

.draggable_bg .menu-box.slide .react-reveal {
  position: absolute;
  right: 10px;
  top: 22%;
}

.menu-box.slide .orderMenu {
  right: 125px;
}

.orderMenu {
  transition: all 0.5s;
}

.draggable_bg .react-reveal {
  position: absolute;
  top: 22%;
}

p.bold {
  font-weight: 700;
  line-height: 1.4;
}

.desSec {
  background: #f5f6f7;
}

.last-menu-items .menu-box:last-child {
  /* margin-bottom: calc(100vh - 350px); */
  /* margin-bottom: 150px; */
  /* box-shadow: 0 0 0 150px var(--light-grey); */
}

.item-details.wht-bg {
  background: #fff;
}

.menu_sec {
  margin-bottom: 15px;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  /* background: white; */

}

.scroll_active .menu_sec {
  margin: 0;
}

#basic-button {
  /* display: flex; */
  border: 1.5px solid #c2c1c1;
  padding: 8px;
  height: 44px;
  text-align: start;
  border-radius: 26px;
  width: 100%;
}

#basic-menu .MuiMenu-list {
  padding: 0 10px;
  width: calc(100% - 0px);
  max-height: 340px;
  overflow: scroll;
}

#basic-menu :first-child {
  justify-content: center;
}

#basic-menu .MuiMenu-list li {
  border-bottom: 2px solid #ddd;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  font-family: "Montserrat";
  padding: 10px 6px;
  background: #fff;
}

#basic-menu .MuiMenu-paper {
  border-radius: 15px;
  /* margin-top: 18px; */
  /* border: 1px solid #c2c1c1; */
  right: 114px;
  /* overflow: visible; */
  box-shadow: 0 0 7px rgb(0 0 0 / 15%) !important;
}

p.bold.desSec {
  color: #5a5655;
  font-size: 14px;
  line-height: normal;
  font-weight: 500;
  background: white;
}

#basic-menu .MuiMenu-paper::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../Assets/images/arrow-up.svg) no-repeat center bottom;
  position: absolute;
  /* transform: rotate(-180deg); */
  right: 12px;
  top: -19px;
  /* opacity: 0.4; */
}

a.listing-anchor {
  background: none;
  color: var(--store-color);
}

a.listing-anchor.blue {
  color: #2793c2;
}

/* body #root.ios-device .last-menu-items {
  background: #f5f6f7;
} */
/*Home height vh*/
body.login,
.login .bottom-sec {
  background: rgb(245, 246, 246);
}

body #root {
  height: calc(100vh - 80px);
}

body.login #root.ios-device {
  overflow: auto;
  height: calc(100vh - 80px);
}

body #root.ios-device {
  max-height: none;
  /* overflow: hidden; */
  /* height: calc(100vh - 158px); */
  height: calc(100vh - 88px);
  min-height: 0;
}

body #root.ios-device .custom_pb {}

body #root.ios-device .last-menu-items {
  /* max-height: calc(100vh - 240px); */
  min-height: 0;
}

#root.ios-device .scroll_active .menu-sections {
  /* height: calc(100vh - 204px); */
  /* height: calc(100vh - 132px); */
  background: #f5f6f7;
}

.promoModal {
  background: none;
}

.promoModal.show {
  top: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0);
  border-radius: 0;
  opacity: 1;
}

.promoModal .varification-sec {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 20px 15px 20px;
  border-radius: 30px 30px 0 0;
  display: none;
}

.promoModal.show .varification-sec {
  display: block;
}

.modal_active .cart_wrapper.custom_pb:after {
  opacity: 0;
  transition: all 0.5s;
}

.modal_active .cart_wrapper.custom_pb:after {
  background: rgba(0, 0, 0, 0.5);
  content: "";
  display: inline-block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: all 0.5s;

  z-index: 999;
}


.completed .outerScroll {
  max-height: calc(100vh - 180px);
}

body.completed #root.ios-device {
  height: calc(100vh - 0px);
}

.mid-sec-des {
  height: calc(100vh - 450px);
  display: flex;
  align-items: center;
}

.login-form h4 {
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  /* margin: 7px auto 0px; */
}

.pay-bill {
  padding: 15px;
  margin: 21px;
  background: #fff;
}

.pay-bill-tip {
  padding: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 12px;
  background: #fff;
  border-radius: 26px;
  border: 2px solid var(--store-color);
}

.pay-bill .title {
  font-weight: 600;
  font-size: 18px;
  color: #363636;
  /* margin: 0 auto 10px; */
}


.pay-bill p {
  font-size: 14px;
  color: #595556;
}

.pink-btn {
  /*background: #1ebdc3;*/
  background: var(--store-color);
  justify-content: center;
  max-width: none;
  margin: 15px auto 0;
}

.middle-container {
  /* padding: 15px 17px; */
  background-color: #F1F1F1;
  max-height: calc(100vh - 180px);
  height: 100vh;
  overflow: auto;
  /* overflow-x: hidden; */
}

.middle-container p {
  font-size: 14px;
}

.blank_btn,
.details-sec .form-control,
.paymentInfo .field.StripeElement {
  background: #fff;
  border: 2px solid #C2C2C2;
  width: 100%;
  padding: 13px 15px 13px;
  margin: 20px auto 20px;
  color: #353535;
  font-size: 16px;
  font-family: "Montserrat";
  text-align: left;
  font-weight: bold;
  display: flex;
  align-items: center;
  border-radius: 26px;
}

.form-control-payment {
  background: #fff;
  border: 2px solid #C2C2C2;
  width: 100%;
  padding: 8px 15px 10px;
  /* margin: 20px auto 20px; */
  color: #353535;
  font-size: 14px;
  font-family: "Montserrat";
  text-align: left;
  /* font-weight: bold; */
  display: flex;
  align-items: center;
  border-radius: 10px;

}

.blank_btn .split-icon,
.sm-sq {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url(../Assets/images/split-icon.svg) no-repeat center center;
  margin: 0 10px 0 0;
  background-size: contain !important;
}

.blank_btn.active {
  border: 2px solid var(--store-hyperlink-color);
  border-radius: 26px;
}

.split-item {
  background: url(../Assets/images/split-items.svg) no-repeat center center;
}

.split-evenly {
  background: url(../Assets/images/split-evenly.svg) no-repeat center center;
}

.split-amount {
  background: url(../Assets/images/custom-amount.svg) no-repeat center center;
  background-size: 20px !important;
}

.order-summary .title {
  font-weight: bold;
  font-size: 18px;
  /* padding: 25px 0 10px; */
  margin: 0;
  display: flex;
  align-items: center;
}

/* .payment-icon{
  display: inline-block;
  width: 22px;
  height: 25px;
  background: url(../Assets/images/user-icon.png) no-repeat center center / cover;
  margin: 0 10px 0 0;
} */

.profile-icon,
.wallet-icon {
  display: inline-block;
  width: 22px;
  height: 25px;
  background: url(../Assets/images/user-icon.png) no-repeat center center / cover;
  margin: 0 10px 0 0;
}

.wht-bg {
  background: #fff;
}

.details-sec label {
  display: inline-block;
  margin-bottom: 0.5rem;
  /* text-transform: uppercase; */
  color: #b2b2b2;
  font-weight: 600;
  font-size: 11px;
  width: 100%;
  margin: 0px auto 5px;
}

.details-sec .form-control {
  /*border: 0; border-bottom: 2px solid #B9B9B9; border-radius: 0;*/
  font-size: 17px;
  font-weight: normal;
  height: auto;
  padding: 10px 15px 12px;
  margin: 5px 0 0;
  font-size: 14px;
  color: #3d3d3d;
}

.details-sec .form-group {
  margin-bottom: 20px;
}

.wallet-icon {
  background: url(../Assets/images/wallet-icon.png) no-repeat center center / contain;
}

.details-sec label strong {
  font-size: 22px;
}

.details-sec .form-group.border-bottom-3 {
  border-bottom: 2px solid #b9b9b9;
}

.like-heart {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 0 10px 0 0;
  /* background: url(../Assets/images/heart-icon.png) no-repeat center left / 30px
    auto; */

  /* background: url(../Assets/images/like-heart.svg) no-repeat center left / 30px auto; */
}

.pay-bill span.sm-box {
  border: 1px solid #e5e5e5;
  height: 70px;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-content: center;
  padding: 5px 0px 5px 5px;
  border-radius: 5px;
}

.edit-icon-btn {
  position: absolute;
  width: 40px;
  height: 100%;
  border: 0;
  background: #fff;
  top: 0px;
  left: 4px;
  background: url(../Assets/images/edit-icons.svg) no-repeat center center;
}

.custom-tip {
  position: relative;
  margin-bottom: 0;
}

.custom-tip .blank_btn {
  padding-left: 50px;
  font-size: 16px;
}

.input-btn {
  max-width: calc(100% - 0px);
  margin: 12px auto 0;
  display: block;
  padding: 10px 0;
  background: var(--store-color);
  border: 2px solid transparent;
  color: #fff;
  border-radius: 26px;
  height: auto;
  width: 100%;
  font-size: 20px;
  font-family: "Montserrat";
  font-weight: 600;
}

.input-pay {
  width: 90% !important;
  height: 47px !important;
  padding: 0px !important;

}

.input-btn.hollow {
  color: var(--store-color);
  background: #fff;
  border: 2px solid var(--store-color);
}

.input-btn.disable {
  background: #adaadf;
}

.confirm-sec {
  text-align: center;
  padding: 15px 0 15px;
}

.confirm-sec p {
  font-size: 14px;
  color: #353535;
}

.lg-title {
  font-size: 22px;
  font-weight: bold;
  margin: 0 auto 20px;
}

.cofirmation-icon {
  display: inline-block;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #ddd;
  background: var(--store-color) url(../Assets/images/checked.svg) no-repeat center center / 32px;
}

.ratings {
  width: 80%;
  max-width: 280px;
  border: 1px solid #acacac;
  border-radius: 30px;
  margin: 10px auto;
  height: 50px;
  background: #fff;
}

.gry-bg {
  background: #f5f5f5;
}

.confirm-sec .total {
  display: flex;
  flex-wrap: wrap;
  width: 260px;
  border-bottom: 2px solid #ddd;
  margin: 20px auto 10px;
  padding: 5px 0;
  color: #ddd;
}

.confirm-sec .total label {
  width: 100%;
  font-size: 18px;
  color: #b2b2b2;
  color: #b2b2b2;
}

.confirm-sec .total label strong {
  color: #b2b2b2;
}

.confirm-sec .total label.secondary,
.confirm-sec .total label.secondary strong {
  color: var(--store-color);
  margin-bottom: 2px;
}

.confirm-sec label {
  color: #878787;
}

.wht-box {
  width: 100%;
  display: flex;
  margin: 0 auto;
  /* max-width: calc(100% - 45px); */
  background: #fff;
  padding: 15px;
  flex-wrap: wrap;
}

.order-details>div {
  padding: 0;
}

.order-details .h2 {
  /* color: #b2b2b2;
  font-size: 15px; */
  font-size: 18px;
  color: #3d3d3d;
  font-weight: bold;
  width: 100%;
  height: auto;
  line-height: normal;
}

.order-details label,
.order-details span {
  width: 100%;
}

.order-details label {
  color: #878787;
  margin: 0;
}

.order-details span {
  font-size: 18px;
  color: #4b4b4b;
  display: inline-block;
}

.summary-table th {
  color: #878787;
  font-weight: normal;
}

.summary-table td {
  font-size: 18px;
}

.summary-table {
  width: 100%;
  margin: 25px auto 0;
}

.order-details .total-paid {
  padding: 10px 10px;
  display: inline-block;
  border-top: 2px solid #dfdfde;
  margin: 20px auto 0;
  font-size: 18px;
}

.payment-confimation {
  max-height: calc(100vh - 55px);
}

.success-con {
  max-height: 100vh;
  height: 100vh;
  padding: 0;
  top: 0px;
  position: fixed;
}

.sm-txt {
  font-size: 14px;
  width: 100%;
  text-align: center;
}

.counts-sec {
  justify-content: space-between;
}

.split-sec {
  align-items: center;
}

.split-sec label {
  font-weight: 600;
  font-size: 13px;
  line-height: 1.3;
  margin: 0;
}

.dec,
.inc {
  border: 2px solid var(--store-color);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background: #fff;
}

.dec {
  background: var(--store-color) url(../Assets/images/minus-icon.svg) no-repeat center center;
}

.inc {
  background: var(--store-color) url(../Assets/images/plus-icon.svg) no-repeat 50% -6px / 17px !important;
}

.counts-sec .count {
  font-size: 22px;
  line-height: 1.4;
  font-weight: bold;
}

.sm-box small {
  font-weight: 600;
  font-size: 13px;
  width: 100%;
  color: #9A9A9A
}

.sm-box {
  position: relative;
  overflow: hidden;
  border: 2px solid#C2C2C2;
  border-radius: 26px;
  height: 95px;
  width: 95px;
  justify-content: center;
}

.sm-box-active {
  position: relative;
  overflow: hidden;
  border: 2px solid var(--store-hyperlink-color);
  border-radius: 26px;
  height: 97px;
  width: 99px;
  justify-content: center;
}

.sm-box input[type="radio"]:checked+label {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #353535;
  left: 0;
  top: 0;
}

.sm-box input[type="radio"]:checked~small {
  color: #fff;
  position: relative;
  font-weight: 600;
}

.sm-box input[type="radio"]:checked~strong {
  color: #fff;
  position: relative;
}

.sm-box strong {
  font-size: 14px;
  font-weight: 500;
}

.radio-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.total-bill {
  background: #fff;
  margin: 10px auto;
}

.total-bill li span,
.total-bill li strong {
  font-size: 18px;
  font-weight: 600;
}

.total-bill li strong {
  float: right;
}

.total-bill li {
  padding: 5px 0;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.total-bill li strong.bold-txt {
  color: #b2b2b2;
  font-weight: bold;
  font-size: 16px;
}

.txt-blue {
  color: #3e38a1;
}

#root.ios-device .toolbar-vh .add-items_sec .item-details.top-scroll {
  height: calc(100vh - 164px);
}

.toolbar-vh .add-items_sec .item-details.top-scroll {
  height: calc(100vh - 164px);
}

.form-group {
  position: relative;
}

.form-group label {
  font-size: 14px;
  font-weight: 600;
  color: #9A9A9A;
}

.dollars {
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  left: 8px;
}

.paying-amount .form-control {
  /*line-height: 1;*/
  padding-left: 30px;
}

/* payment info page */
.paymentInfo .outcome {
  float: left;
  width: 100%;
  padding-top: 8px;
  min-height: 24px;
  text-align: center;
}

.paymentInfo .success,
.paymentInfo .error {
  display: none;
  font-size: 13px;
}

.paymentInfo .success.visible,
.paymentInfo .error.visible {
  display: inline;
}

.paymentInfo .error {
  color: #e4584c;
}

.paymentInfo .success {
  color: #666ee8;
}

.paymentInfo .success .token {
  font-weight: 500;
  font-size: 13px;
}

#card-holder-name::placeholder {
  color: #a5afb5;
}

#card-holder-name {
  color: #333;
  /* font-family: "Maven Pro", sans-serif !important; */
  font-size: 14px !important;
}

.custom-select {
  height: auto;
  padding: 10px 15px 12px;
  margin: 5px 0 0;
  font-size: 14px;
  color: #3d3d3d;
  border: 2px solid #C2C2C2;
  border-radius: 10px;
  color: #3d3d3d;
  background: white;
  font-family: "Montserrat";
}

.paymentInfo .field {
  background: transparent;
  /* font-weight: 300; */
  border: 0;
  outline: none;
  flex: 1;
  cursor: text;
  border-bottom: 1px solid #ddd;
  padding: 8px 0px;
  height: 40px;
  color: #333 !important;
  font-size: 13px !important;

  font-family: "Montserrat" !important;
}

.paymentInfo .field::-webkit-input-placeholder {
  color: #a5afb5;
}

.paymentInfo .field::-moz-placeholder {
  color: #a5afb5;
}

/* payment info page END*/
.add-items_sec .item-details.top-scroll {
  max-height: calc(100vh - 220px);
}

#root.ios-device .add-items_sec .item-details.top-scroll {
  max-height: calc(100vh - 250px);
}

.add-items_sec .item-details {
  /* height: calc(100vh - 371px); */
  /* height: 100vh; */

}

/* #root.ios-device .add-items_sec .item-details { height: calc(100vh - 451px);} */
#root.ios-device .toolbar-vh .add-items_sec .item-details.top-scroll {
  max-height: calc(100vh - 160px);
}

.selectItems li span,
.selectItems li strong,
.selectItems li.total span {
  text-transform: none;
  color: #353535;
}

.dec,
.inc {
  height: 30px;
  width: 30px;
  min-height: 24px;
  min-width: 24px;
  line-height: 5;
  background-size: 14px;
  /* display: inline-block; */
  display: flex;
}

.filled {
  height: 27px;
  width: 27px;
  border: 0;
  background: var(--store-color) url(../Assets/images/checked.svg) no-repeat center center / 16px;
  border-radius: 50%;
}

.counts-sec-end {
  justify-content: end;
}

button[disabled] {
  opacity: 0.2;
}

button[disabled].dec.disabled {
  opacity: 0;
}

.paymentInfo .field.StripeElement>div {
  width: 100%;
  flex: 0 0 100%;
}

.accordion_button:after {
  display: inline-block;
  content: "";
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 3px solid currentColor;
  border-right: 3px solid currentColor;
  transform: rotate(45deg);
  position: absolute;
  right: 3px;
  transition: all 0.3s;
  top: 12px;
}

.accordion_button[aria-expanded="false"]:after {
  transform: rotate(-135deg);
  top: 16px;
}

.accordion {
  padding: 5px;
  overflow: hidden;
  border-radius: 10px !important;
  margin-top: 20px;
  background: #fff;
}

.accordion p {
  padding: 5px 10px;
  font-weight: bold;
  font-size: 17px;
  color: #363636;
}

.accordion__panel {
  padding: 0 !important;
}

.accordion__item {
  position: relative;
}

.accordion_button {
  position: absolute;
  right: 0;
  top: 0;
}

.btm-buttons {
  position: relative;
  /* bottom: 18%; */
  padding: 20px 5px 10px;
}

#root.ios-device .toolbar-vh .add-items_sec .item-details.top-scroll {
  height: calc(100vh - 200px);
}

.menu-box .label_row {
  min-width: 100%;
}

/*price change*/
.menu-box .label_row {
  display: inline-block;
}

.menu-content p.pricing.strike-off {
  float: left;
}

.menu-content p.promotion-price {
  float: left;
}

/*/price change*/
.text-padding-left {
  padding-left: 45px;
}

.header_top {
  height: 55px;
}

.imgLogo {
  display: flex;
  justify-content: center;
  margin-top: -55px !important;
}

.circle-img.imgLogo figure {
  width: 90px;
  height: 90px;
  padding: 4px;
  border: 3px solid #fff;
  background: #f5f6f6;
}

.circle-img.imgLogo figure img {
  border-radius: 50%;
}

.loc-icon {
  /* background: url(../Assets/images/green_loc_mark.svg) no-repeat center center; */
  width: 17px;
  height: 30px;
  display: inline-block;
  margin-right: 5px;
}

.varification-sec .login-form h5.title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.varification-sec .header-title h5.title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}

.varification-sec .login-form h2 {
  font-size: 30px;
  justify-content: center;
  display: flex;
  margin: -10px 0 30px;
  line-height: 1;
  align-items: center;
}

.varification-sec .header-title h2 {
  font-size: 22px;
  justify-content: center;
  display: flex;
  margin: 1px 0 10px;
  line-height: 1;
  align-items: baseline;
}

.varification-sec .login-form h2 strong {
  margin: 0 0 0 10px;
  font-size: 40px;
  line-height: 1;
}

.varification-sec .header-title h2 strong {
  margin: 0 0 0 10px;
  font-size: 25px;
  line-height: 1;
}

.login-form .green-btn.new-green {
  margin: 0 auto !important;
  display: inline-block;
  float: none !important;
  padding: 10px 25px;
  width: auto;
  color: #fff;
  min-width: 150px;
  font-size: 16px;
  font-weight: bold;
}

.login-form .resend_row,
.login-form .guest_login {
  text-align: center;
}

.login-form .resend_btn_row {
  display: flex;
  justify-content: space-between;
}

.login-form .guest_login {
  margin-top: 30px;
}

.title-heading.bold {
  color: #9f9f9f;
  text-transform: uppercase;
  font-weight: 700;
  background: #fff;
  /*border-radius: 50%;*/
  position: relative;
  padding: 0 0;
  font-size: 20px;
}

.title-clr {
  color: var(--black) !important;
}

.title-heading-login {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 20px;
  line-height: 24.34px;
}

.title-heading.text-green {
  color: var(--store-color);
  margin: 0 auto 30px;
}

.green-box {
  padding: 13px 20px;
  border: 2px solid var(--store-color);
  border-radius: 20px;
  color: #585555;
}

.group-sec {
  margin: 0 auto 20px;
  text-align: center;
}

.group-sec p {
  font-size: 15px;
}

.customers-selection {
  display: flex;
  justify-content: space-between;
}

.group {
  width: 50%;
  text-align: center;
  max-width: 160px;
}

.group label {
  display: flex;
  width: 100%;
  height: 143px;
  border: 2px solid #b3b3b3;
  border-radius: 31px;
  margin: 0;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.group label h4 {
  color: var(--black);
  font-weight: bold;
}

.newCust,
.lock,
.existCust {
  display: inline-block;
  width: 100%;
  height: 60px;
  background: url(../Assets/public/useradd-11.svg) no-repeat center center;
  margin: 0 auto 5px;
}

.existCust {
  background: url(../Assets/public/following-11.svg) no-repeat center center;
}

.lock{
  background: url(../Assets/public/lock.svg) no-repeat center center;
}

.group input[type="radio"]:checked+label {
  border-color: var(--store-color);
}

.group input[type="radio"]:checked+label h4 {
  color: var(--store-color);
}

.customer-ms .header-title h5.title {
  font-size: 20px;
}

.customers-selection {
  max-width: 360px;
  margin: auto;
  padding: 0 10px;
}

.customers-selection input {
  display: none;
}

.customer-ms .or {
  margin: 30px auto;
}

.customer-ms {
  min-height: calc(100vh - 175px);
}

body.login #root.ios-device {
  height: calc(100vh - 50px);
}

.customer-ms .login-form {
  max-height: 70vh;
  overflow: auto;
}

body.login #root.ios-device .customer-ms .login-form {
  max-height: 70vh;
  overflow: auto;
}

body.login #root {
  max-height: calc(100vh - 0px);
  height: calc(100vh - 70px);
}

body.login #root.ios-device .customer-ms {
  min-height: calc(100vh - 155px);
}

body.login #root.ios-device .customer-ms .login-form {
  height: 60vh;
  max-height: 60vh;
  overflow: auto;
}

body.login #root.ios-device .toolbar-vh .customer-ms {
  min-height: calc(100vh - 185px);
}

body.login #root.ios-device .toolbar-vh .customer-ms .login-form {
  height: 80vh;
  max-height: 65vh;
  overflow: auto;
}

/* 
.varification-sec {font-size: 0.8rem;}
.varification-sec .header-title .title {
  font-size: 1.8rem;
}
.varification-sec .header-title h2 { font-size: 25px;}
.varification-sec .group-sec p {
  font-size: 14px;
}
.login-form .green-btn.new-green {
  font-size: 14px;
} */
.menu-content p.promotion-price,
.menu-content p.pricing.strike-off {
  padding-right: 10px;
}

.menu-content p.promotion-price {
  margin-left: 0 !important;
}

.accordion__heading {
  position: relative;
}

.h5 p strong {
  font-size: 18px;
}

ul.billing-list.pay-detail li {
  height: 50px;
}

.accordion {
  border: 0 !important;
  background: none;
}

.accordion .AccordionItems.active {
  border: 1px solid #ddd;
}

.accordion .AccordionItems {
  overflow: hidden;
  border: 2px solid var(--store-hyperlink-color);
  border-radius: 26px;
  margin: 0 auto 15px;
  background: #fff;
}

.accordion .h5 {
  margin: 0;
  padding: 10px 5px;
  font-size: 17px;
}

.accordion_button {
  top: 8px;
  width: 40px;
  height: 40px;
}

ul.billing-list.pay-detail li:last-child {
  border: 0;
}

.modal_comp.show .modal.loyality_ms.top50 {
  top: 50%;
}

.modal_comp.show {
  z-index: 99;
}

.acordion-wrap {
  height: calc(100vh - 250px);
}

.acordion-wrap .accordion {
  width: 100%;
}

.acordion-wrap .btm-buttons {
  width: 100%;
  align-self: end;
}

body #root.success-ms {
  height: calc(100vh - 50px);
}

body #root.success-ms.ios-device {
  height: calc(100vh - 0px);
}

body #root.success-ms.ios-device .toolbar-vh {
  height: calc(100vh - 0px);
}

.ios-device .success-con {
  max-height: calc(100vh - 80px);
}

.ios-device .toolbar-vh.success-con {
  max-height: none;
}

.ios-device .dec,
.ios-device .inc {
  height: 32px;
}

.ios-device .inc {
  background-position: 50% -3px !important;
}

/*Mobiles*/
/* html {
  display: inline-block;
  width: 400px;
  height: 830px;
  margin: 30px auto;}
.ios {
  display: inline-block;
  width: 400px;
  height: 830px;
  background: url(../Assets/images/ios.png) no-repeat center center / contain;
  margin: 30px auto;}

  .inner-content {
    width: 96%;
    height: 94.5%;
    position: relative;
    background: #fff;
    margin: 33px auto 0;
    border-radius: 36px;
    overflow: hidden;} */

/*New changes*/
.input-btn {
  margin: 0px auto;
  height: 56px;
  box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.08);
}

.payment-confimation {
  z-index: 11;
}

.form-control.invalid,
.custom-select.invalid {
  border-color: red;
  box-shadow: none;
}

.card-img:after {
  content: "";
  display: flex;
  width: 40px;
  height: 25px;
  background: url(../Assets/images/credit-card.png) no-repeat center center/ cover;
  position: absolute;
  right: 11px;
  top: 36px;
}

.card-img.american-express:after {
  background: url(../Assets/images/amex-card.svg) no-repeat center center/ cover;
}

.card-img.diners-club:after {
  background: url(../Assets/images/diners-card.svg) no-repeat center center/ cover;
}

.card-img.discover:after {
  background: url(../Assets/images/discover-card.svg) no-repeat center center/ cover;
}

.card-img.jcb:after {
  background: url(../Assets/images/jcb-card.svg) no-repeat center center/ cover;
}

.card-img.mastercard:after {
  background: url(../Assets/images/mastercard-card.svg) no-repeat center center/ cover;
}

.card-img.unionpay:after {
  background: url(../Assets/images/unionpay-card.svg) no-repeat center center/ cover;
}

.card-img.visa:after {
  background: url(../Assets/images/visa-card.svg) no-repeat center center/ cover;
}

.card-img input {
  padding-right: 70px !important;
}

apple-pay-button {
  --apple-pay-button-width: 150px;
  --apple-pay-button-height: 30px;
  --apple-pay-button-border-radius: 3px;
  --apple-pay-button-padding: 0px 0px;
  --apple-pay-button-box-sizing: border-box;
}

.modal_comp.show {
  z-index: 99;
}

.search-bar.input-text::-webkit-search-cancel-button {

  /* Remove default */
  -webkit-appearance: none;

  /* Now your own custom styles */
  height: 14px;
  width: 14px;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  /* setup all the background tweaks for our custom icon */
  background-repeat: no-repeat;

  /* icon size */
  background-size: 14px;
  /* background: red; */

}

.round-button {
  display: block;
  width: 80px;
  height: 80px;
  line-height: 80px;
  border: 2px solid #f5f5f5;
  border-radius: 50%;
  color: #f5f5f5;
  text-align: center;
  text-decoration: none;
  background: #555777;
  box-shadow: 0 0 3px gray;
  font-size: 20px;
  font-weight: bold;
  margin: 15px 23px;
}

.round-button:hover {
  background: #777555;
}

.round-button.border-0 {
  border: none;
  box-shadow: none;
  background: inherit;
}

.pin-input-con {
  justify-content: center;
}

.pin-input {
  width: 35px;
  margin-right: 10px;

}


.pin-input:nth-child(4) {
  margin-right: 0px;
}



/* new */

.new-log-screen .varification-sec .circle-img {
  margin: inherit;
}

.new-log-screen .circle-img.default figure {
  margin: 0;
  border-color: white;
  border-width: 2px;
  background: none;
  max-width: 120px;
  max-height: 120px;
}

.new-log-screen .boat-text {
  font-size: 25px;
  font-weight: bold;
}

.new-log-form {
  background: white;
}

.new-log-form .group-sec {
  text-align: inherit;
}

.new-log-form .pin-text {
  font-size: 16px;
  font-weight: 800;
  color: #565656;
}

.new-log-form .title-heading.text-green {
  margin-bottom: 10px;
  font-size: 22px;
  text-transform: capitalize;
}

.new-log-form .round-button {
  --height-calc: calc((100vh - 425px) / 5);
  line-height: inherit;
  background: white;
  color: #565656;
  border-color: #565656;
  font-size: 35px;
  font-weight: 600;
  margin: 7px;
  width: var(--height-calc);
  height: var(--height-calc);
  padding: 0px;
  max-width: 55px;
  max-height: 55px;
}

.new-log-form .round-button:hover {
  background: white;
  border-color: var(--store-color);
}

.new-log-form .pin-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 25%; */
}

.new-log-form .pin-input-con {
  margin: 10px;
}

.new-log-form .pin-input-con input {
  border: none;
  border-bottom: 1px solid #565656;
  text-align: center;
  font-size: 22px;
  color: #565656;
  font-weight: 600;
  border-radius: 0px;
  padding: 0px;
}


.new-log-form .resend_btn_row {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f5f6f7;
  box-shadow: none;
  outline: none;
  border: 1px solid #f5f6f7;
  max-width: 767px;
  z-index: 1;
  padding-top: 15px;
  left: 0;
  right: 0;
  margin: auto;
  padding-bottom: 15px;
}


.login-form.new-log-form .green-btn.new-green {
  display: inline-block;
  float: none !important;
  width: 100%;
  color: #fff;
  min-width: inherit;
  font-size: 20px;
  font-weight: 600;
  border-radius: 26px;
  margin-left: 15px !important;
  margin-right: 15px !important;
  background-color: var(--store-color);
}

/*body #root {
    height: calc(100vh - 125px);
}*/

body #root.scroll-phone {
  overflow: hidden;
  height: 100%;


}

body #root.scroll-table {
  overflow: auto !important;
  height: calc(100vh - 88px) !important;
}

@media (min-width: 300px) and (max-width: 380px) {

  .login-form.new-log-form .green-btn.new-green {
    font-size: 16px;
  }

  .new-log-form .title-heading.text-green {
    margin-bottom: 5px;
  }

  .new-log-screen .boat-text {
    font-size: 25px;
    font-weight: bold;
  }
}




/* Table Viewer  */


.new-table-form .pr-table {
  padding-right: 10px !important;
}

.new-table-form .pl-table {
  padding-left: 10px !important;
}

.new-table-screen .varification-sec .circle-img {
  margin: inherit;
}

.new-table-screen .circle-img.default figure {
  margin: 0;
  border-color: white;
  border-width: 2px;
  background: none;
  max-width: 120px;
  max-height: 120px;
}

.new-table-screen .boat-text {
  font-size: 25px;
  font-weight: bold;
}

.new-table-form {
  background: white;
}

.new-table-form .group-sec {
  text-align: inherit;
  margin-bottom: 0px;
}

.new-table-form .pin-text {
  font-size: 16px;
  font-weight: 800;
  color: #565656;
}

.new-table-form .title-heading.text-green {
  margin-bottom: 10px;
  font-size: 25px;
  text-transform: capitalize;
  color: #4a4a4a;
}

.new-table-form .round-button {
  line-height: inherit;
  background: white;
  color: #565656;
  border-color: #565656;
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
  width: 16vw;
  height: 16vw;
  border-radius: 12px;
  min-width: 60px;
  border-width: 1.8px;
  max-width: 80px;
  max-height: 80px;
}

.new-table-form select.form-control {
  border-width: 1.8px !important;
  border-color: #565656;
  border-radius: 12px;
  height: 56px;
  /*  -webkit-apperance:inherit;
  appearance: auto;
*/
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  /*    height: 45px;*/
  width: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='18' viewBox='0 0 26 18'%3E%3Cg id='Group_679' data-name='Group 679' transform='translate(612 924)'%3E%3Crect id='Rectangle_402' data-name='Rectangle 402' width='26' height='18' transform='translate(-612 -924)' fill='rgba(255,255,255,0)'/%3E%3Cpath id='Path_335' data-name='Path 335' d='M340.344,14.643a.894.894,0,0,0,.356.694,1.468,1.468,0,0,0,1.747,0l5.818-4.64,5.826,4.64a1.456,1.456,0,0,0,1.739,0,.846.846,0,0,0,0-1.388l-6.692-5.336a1.408,1.408,0,0,0-.873-.29,1.43,1.43,0,0,0-.874.29L340.7,13.949a.886.886,0,0,0-.356.694' transform='matrix(-1, 0.017, -0.017, -1, -253.576, -909.106)' fill='%234b4b4b'/%3E%3C/g%3E%3C/svg%3E");
  background-position: 97%;
  background-repeat: no-repeat;
}

.login-form .container-fluid {
  margin-bottom: 100px;
}

.new-table-form .round-button:hover,
.new-table-form .round-button.active {
  background: white;
  border-color: var(--store-color) !important;
}

.new-table-form .pin-sec {
  /*display: flex;
  justify-content: space-between;
  align-items: center;*/

  display: grid !important;
  grid-template-columns: auto auto auto auto auto !important;
  gap: 1vw;
  grid-gap: 2vw;
  margin-bottom: 15px;
  padding: 0px !important;
  width: 100%;
}

.new-table-form .resend_btn_row {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f5f6f7;
  box-shadow: none;
  outline: none;
  border: 1px solid #f5f6f7;
  max-width: 767px;
  z-index: 1;
  padding-top: 15px;
  left: 0;
  right: 0;
  margin: auto;
  padding-bottom: 15px;
}

.login-form.new-table-form .green-btn.new-green {
  display: inline-block;
  float: none !important;
  width: 100%;
  color: #fff;
  min-width: inherit;
  font-size: 22px;
  font-weight: bold;
  border-radius: 10px;
  margin-left: 15px !important;
  margin-right: 15px !important;
}


@media (min-width: 280px) and (max-width: 349px) {
  .new-table-form .round-button {
    line-height: inherit;
    background: white;
    color: #565656;
    border-color: #565656;
    font-size: 16px;
    font-weight: 600;
    margin: 0px;
    width: auto;
    height: 50px;
    border-radius: 8px;
    min-width: 50px;
    border-width: 1.8px;
  }

  .new-table-form .pin-sec {
    margin-bottom: 10px;
  }

  .new-table-form select.form-control {
    border-radius: 8px;
    height: 50px;
    -webkit-apperance: inherit;
    appearance: auto;
    font-size: 14px;
  }

  .login-form.new-table-form .green-btn.new-green {
    font-size: 16px;
  }

  .new-table-screen .boat-text {
    font-size: 25px;
    font-weight: bold;
  }

  .new-table-screen .circle-img.default figure {
    height: 80px;
    width: 80px;
  }
}




@media (min-width: 500px) and (max-width: 767px) {
  .new-table-form .round-button {
    line-height: inherit;
    background: white;
    color: #565656;
    border-color: #565656;
    font-size: 20px;
    font-weight: 600;
    margin: 0px;
    width: 16vw;
    height: 16vw;
    border-radius: 12px;
    min-width: 75px;
    border-width: 1.8px;
  }

  .new-table-form .pin-sec {
    margin-bottom: 25px;
    grid-gap: 20px;
  }
}


#redirectedCon .input-btn {
  padding: 10px 50px;
  width: auto;
}



/* .wrapper.custom_pb .welcome-sec{
  justify-content: space-between;
}



header .welcome-sec .right_col{
  width: inherit;
} */
.wrapper.custom_pb .welcome-sec .logoSize {
  width: 140px;
}

.test {
  /* width: calc(100% - 88px); */
  padding-left: 140px;
}


/* .filterActive {
  margin-left: 15px;
  margin-right: 15px;
} */


.scroll_active .menus_row .menu-row {
  margin-left: 0px;
}

.scroll_active .menus_row .menu-row {
  width: 100%;
}

.scroll_active .middle-sec .menu-row .link-box {
  padding-left: 0px !important;
}

.container-flex {
  display: flex;
  flex-direction: column;
  height: 100vh;

}

.flex-top {
  flex-shrink: 0;
  align-self: flex-start;
  width: 100%;
  position: sticky;
}


.login-form.pt-4.px_15.new-log-form {
  /* height: auto; */
  overflow: scroll;
  flex-grow: 1;
  margin-bottom: 135px;
}


.new-log-form .resend_btn_row {
  position: relative;
  width: 100%;
  /* margin-top: 10px; */
  z-index: 1;
}

.fixedBottom {
  flex-shrink: 0;
  align-self: flex-end;
  width: 100%;
  position: fixed;
  bottom: 0;
  max-width: 767px;
}

body #root {
  /* height: 100% !important;
  max-height: 100% !important; */
  max-width: 767px;
  margin: auto;
}

.login-form.pt-4.px_15.new-table-form {
  /* height: auto; */
  overflow: scroll;
  /* height: 100%; */
  flex-grow: 1;
  margin-bottom: 85px;
}


.login-form.new-table-form .form-control:focus {
  box-shadow: none;
  outline: none;

}

.menu-box .new-items-num {
  color: var(--black);
  font-family: "Montserrat";
  font-weight: 600;
  width: 30px !important;
  font-size: 15px !important;
  height: 35px;
}

.items-num {
  border: 0;
  appearance: none;
  -webkit-appearance: none;
  text-align: center;
  font-weight: 700;
  color: var(--black);
  width: 45px;
  height: 40px;
  font-size: 22px;
}

.menu-box .new-add-remove {
  /* border-radius: 50%;
  width: 20px!important;
  height: 20px!important;
  border: 1.5px solid #bababa; */
  border-radius: 0%;
  width: auto !important;
  height: auto !important;
  border: none;
  background: #fff !important;
  box-shadow: none !important;
  margin-top: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  /* line-height: .6; */
  /* line-height: .6; */

}

.menu-box .new-add-add {
  border-radius: 50%;
  height: 20px !important;
  width: 20px !important;
  border: 1.5px solid #bababa;
  background: #fff !important;
  box-shadow: none !important;
  margin-top: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  line-height: .6;
}

.items-sec-end {
  margin: 5px 10px 0px 10px;
  padding: 0px 5px;
  border: 2px solid #bababa;
  border-radius: 5px;
  justify-content: space-around;
  height: 44px;
}

.key-pad {
  height: calc(100% - 130px);
  overflow: scroll;
}

.close-buttons.input-text::-webkit-search-cancel-button {

  /* Remove default */
  -webkit-appearance: none;

  /* Now your own custom styles */
  height: 14px;
  width: 14px;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  /* setup all the background tweaks for our custom icon */
  background-repeat: no-repeat;

  /* icon size */
  background-size: 14px;
  /* background: red; */

}

.new-table-form .table-selectBtn .table-PrefixControl__control {
  border-width: 1.8px !important;
  border-color: #565656;
  border-radius: 12px;
  height: 56px;
  /*  -webkit-apperance:inherit;
  appearance: auto;
*/
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  /*    height: 45px;*/
  width: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='18' viewBox='0 0 26 18'%3E%3Cg id='Group_679' data-name='Group 679' transform='translate(612 924)'%3E%3Crect id='Rectangle_402' data-name='Rectangle 402' width='26' height='18' transform='translate(-612 -924)' fill='rgba(255,255,255,0)'/%3E%3Cpath id='Path_335' data-name='Path 335' d='M340.344,14.643a.894.894,0,0,0,.356.694,1.468,1.468,0,0,0,1.747,0l5.818-4.64,5.826,4.64a1.456,1.456,0,0,0,1.739,0,.846.846,0,0,0,0-1.388l-6.692-5.336a1.408,1.408,0,0,0-.873-.29,1.43,1.43,0,0,0-.874.29L340.7,13.949a.886.886,0,0,0-.356.694' transform='matrix(-1, 0.017, -0.017, -1, -253.576, -909.106)' fill='%234b4b4b'/%3E%3C/g%3E%3C/svg%3E");
  background-position: 97%;
  background-repeat: no-repeat;
}

.new-table-form .table-selectBtn .table-PrefixControl__control:focus {
  box-shadow: none !important;
  outline: none !important;
}

.new-table-form .table-selectBtn .table-PrefixControl__indicators {
  display: none;
}


.accountModal.newPopupmodal.show {
  top: 0px;
  border-radius: 0px;
}

.accountModal.newPopupmodal .varification-sec {}

.accountModal.newPopupmodal .varification-sec .h3-popup {
  font-weight: 700;
  color: #5b5b5d;
  font-size: 18px;
  padding: 0px 10px;
  text-align: center;
}

.accountModal.newPopupmodal .varification-sec .h3-popups {
  padding: 17px 15px 0px;
}

.accountModal.newPopupmodal .newOrderPopup .new-p1 {
  background: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  padding-left: 15px;

}

.accountModal.newPopupmodal .newOrderPopup p {
  /* padding-left: 15px; */
  background: #fff;
}

.accountModal.newPopupmodal .newOrderPopup .new-p2 {
  padding: 10px 0px;
  /*    border-bottom: 1px solid #ddd;*/
}

.accountModal.newPopupmodal .newOrderPopup .new-p3 {
  padding: 6px 10px;
}

.accountModal.newPopupmodal .newOrderPopup .new-p4 {
  padding: 6px 10px;

}

/* .accountModal.newPopupmodal .newOrderPopup {
  padding: 15px 0px 0px 0px;
  background: #f5f6f7;
} */

/*.item-details .selectors .options {
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
}

.sm-title {
    margin: 0;
    padding: 8px 20px;  
}*/

/*.accountModal.newPopupmodal .cross-btn {
    -webkit-filter: grayscale(1) invert(1) !important;
    filter: grayscale(1) invert(1) !important;
}
*/

/*.accountModal.newPopupmodal .varification-sec .h3-popups{
    padding-left: 20px;
    font-weight: 700;
    color: #5b5b5d;
    font-size: 16px;
    background: #f5f6f8;
    padding: 15px 15px;
    margin-bottom: 10px;
    margin-top: 10px;
}*/

/* .accordion {
  overflow: hidden;
  border: 1px solid #1ebdc3;
  border-radius: 8px;
  margin: 0 auto 15px;
  background: #fff;
} */

/* .accordion h5 {
  margin: 0;
  padding: 10px 5px;
  font-size: 17px;
} */
/* .collapse:not(.show) {
  display: ;
} */



.accordion.accorDion {
  padding: 0px 17px;
}

.accordion-btn-table {
  padding: 0px 17px;
}

/* #accordionExample {
  overflow: inherit !important;
} */

.accorDion .accordion-item {
  border: 1px solid var(--store-color);
  border-radius: 5px;
  background: #fff;
}

.accorDion .accordion-item.default {
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  background: #fff;
}

.accorDion .accordion-header {
  padding: 10px 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  transition: all 0.3s;
}

.accorDion .accordion-button:not(.collapsed) {
  font-size: 17px;
  font-weight: 700;
  border: none;
  /* background: #fff;
    padding: 5px 10px; */
  color: #363636;
}

.ac-accordion-icon {
  transition: all 0.3s;
}

/* .accorDion .accordion-collapse .accordion-body{
   padding: 5px 10px;
} */

.ac-accordion-icon {
  padding: 5px 10px;
}


.accorDion .accordion-button:focus {
  box-shadow: none;
}

.dropDown-Text {
  padding: 5px 15px !important;
  font-weight: normal !important;
}

.accorDion .newOrderPopup.d-flex.justify-content-between {
  padding-bottom: 7px;
  padding-top: 7px;
  border-bottom: 1px solid #e8e8e8;
}

.order-summary .title {
  font-weight: 700;
  font-size: 18px;
  /* padding: 25px 0 10px; */
  margin: 0;
  display: flex;
  align-items: center;
}

/* .order-summary .title {
  font-weight: 700;
  font-size: 18px;
  padding: 25px 0 10px;
  margin: 0;
  display: flex;
  align-items: center;
} */


.OrderHeads {
  padding: 15px 17px 10px 17px;
}

.OrderHeads .title {
  font-weight: 700;
  font-size: 22px;
  padding: 5px 0 10px;
  margin: 0;
}

.OrderHeads p {
  font-size: 14px;
  margin: 0px;
}

.OrderHeadsBtn {
  border-radius: 5px;
  box-shadow: none;
  height: auto;
}

.OrderHeadsPadding {
  padding-left: 17px;
  padding-right: 17px;
}

.accordion-btn-table .btm-buttons {
  padding-left: 0px;
  padding-right: 0px;
}

.Disabled_btn {
  background-color: #c5c5c5;
  opacity: 1 !important;
}

.Exit-Btns {
  background: #fff !important;
  border: 2px solid var(--store-color);
}



.Exit_Img {
  /* background:var(--store-color) url(../Assets/images/logout.svg) no-repeat center center / 100% auto ; */
  width: 20px;
  height: 20px;
  color: var(--store-color);
}

.exit-max-width {
  max-width: 100% !important;
}

.display_Hidden {
  display: none;
}

.strong-size {
  font-size: 16px !important;
}

.accordion-buttonSpan::after {
  flex-shrink: 0;
  margin-right: 12px;
  content: "";
  border-bottom: 3px solid;
  border-right: 3px solid;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;
  height: 10px;
  width: 10px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.accordion-buttonSpan[aria-expanded=false]:after {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  margin-top: 8px;
}

.filled-green {
  background: var(--store-color) !important;
  color: #f5f5f5 !important;
  border: var(--store-color) !important;

}

.green-sec .total-price strong.text {
  font-size: 20px;
}

.apple_btn {
  width: 100%;
  /* height: 50px; */
  height: 55px !important;
  background-color: white;
  border-radius: 26px;
  padding: 12px;
  box-sizing: border-box;
  border: 1px black solid;
  margin-top: 10px;
}

.google_btn {
  width: 100%;
  height: 55px !important;
  background-color: white;
  border-radius: 26px;
  padding: 10;
  box-sizing: border-box;
  border: 1px white solid;
  margin-top: 20px;
}







.pay-apple {
  width: 80px;
}

.new_apple_btn {
  border: 1px solid #D8D8D8;
  height: auto;
}

.new_apple_btn_border {
  border: 1px solid var(--store-hyperlink-color);
  height: auto;

}

.pay-credit {
  height: 37px;
}

.cardPayment {
  display: none;
}

.btn-success {
  background-color: var(--store-color);
  border-color: var(--store-color);

}

.paymentText {
  text-align: start;
  font-weight: 700;
  font-size: 16px;
  color: var(--black) !important;
  font-family: "Montserrat";
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}

.google_paymentText {
  text-align: start;
  font-weight: 700;
  font-size: 16px;
  color: var(--black) !important;
  font-family: "Montserrat";
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;*/
}

.pay-google {
  /* height: 23px; */
  width: 70px;


}

.hotel_name_icon {
  display: flex;
  align-items: center;
}

.menu-sections.removeHeight {
  height: auto !important;
  overflow-y: auto;
}

.removeHeight {
  height: auto !important;
  overflow-y: auto;
}

.sticky-top-menu {
  position: sticky;
  top: 0px;
  padding: 10px 0px !important;
  z-index: 99999999;
}

.item-tag {
  font-weight: 600;
  font-size: 13px;

}

.menu-title {
  font-weight: 600;
  font-size: 15px;
}

.logo {
  height: 90%;
  width: 90%;
}

.logo-grid {
  display: flex,

}



.translate-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  max-width: 100px;
  max-height: 100px;
  border-radius: 50%;
  margin-top: -68px;
  background-color: white;


}

.image-border {
  background: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: -20px;
  position: relative;
  /* z-index: 999; */
}

.appheader-call {
  color: var(--store-color);
  font-weight: 700;
  font-size: 14px;

}

.background-image {
  height: 182px;
  width: 100%;
  object-fit: cover;
}

.background-hide-image {
  display: none;
}

.card-radius {
  border-radius: 30px;
  border: 2px solid #9C9C9C;
}

.or-line {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.or-line span {
  background: #fff;
  padding: 0 10px;
}

.custom-btn {
  background: var(--store-color);
  box-shadow: 0px 0px 12px 0px #00000040;
  border-radius: 50px;
  margin-top: 20px;
  color: white !important;
  font-family: "Montserrat";
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  padding: 10px 20px;
  width: 191px;
  /* height: 46px; */
}

.custom-btn:hover {
  background: var(--store-color);
  color: white !important;
}

.table-name {
  color: var(--black);
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  line-height: normal !important;

}

.input-icons {
  width: 21px;
  height: 21px;
  flex-shrink: 0;
}

.back-text {
  color: var(--store-color);
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

}

/* .customer{
  border: 2px solid black;
  border-radius: 5px;
  flex: content;
  /* align-items: center; 
  justify-content:space-evenly;
} */

.radio-btn {
  border: 4px solid white;
  /* box-shadow: 0 0 0 1px var(--store-color); */
  appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition: all ease-in 0.2s;
  height: 25px;
  width: 25px;

}

.radio-btn:checked {
  background-color: var(--store-color);
}


.input-radius {
  border-radius: 10px;
  border: 1.5px solid #C2C2C2;
  height: 46px;
}

.mleft-5 {
  margin-left: 35px;
}

.loading-overlay {
  display: flex;
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9998;
  align-items: center;
  justify-content: center;
}

.Toastify__toast {
  min-height: 0 !important;
}

.Toastify__toast-body {
  padding: 0 6px !important;
  margin: 0 !important;
}

.Toastify__toast-container--bottom-left,
.Toastify__toast-container--bottom-center,
.Toastify__toast-container--bottom-right,
.Toastify__toast-container--bottom-left,
.Toastify__toast-container--bottom-center,
.Toastify__toast-container--bottom-right {
  bottom: auto !important;
  top: 0 !important;
  -webkit-transform: translateX(0);
}

.Toastify__toast--success {
  background: var(--store-color) !important;
}

.border-voilet {
  border-radius: 31px;
  border: 2px solid var(--store-color);
  background: #FFF;
}

.qrcode_modal {
  top: unset !important;
}

.qrcode_modal.show {
  transform: translateY(0);
  left: 0;
  top: 40% !important;
}

.accountModal,
.promoModal,
.qrcode_modal,
.phone_agreement_modal {
  position: fixed;
  top: 100%;
  background: #f5f6f7;
  overflow: auto;
  width: 100%;
  border-radius: 30px 30px 0 0;
  bottom: 0;
  transform: translateY(100%);
  transition: 0.3s all;
  max-width: 767px;
}

.bottom-sec {
  color: #fff;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  bottom: 0;
  /* background: #fff; */
  max-width: 767px;
  z-index: 11;
}

.subcat-title {
  font-weight: 600;
  font-size: 16px;
  color: var(--black);

}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}



/* .card .row {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
} */

.card-title {
  color: var(--black);
  font-weight: 600;
  font-size: 13px;
}

.item-image {
  border-radius: 6px;
  max-height: 86px;
  object-fit: cover;

}

.permotion-image {
  border-radius: 4px;
  height: 150px;
  max-height: 150px;
  object-fit: cover;

}

.filter-tags {
  color: var(--gray-1);
  font-weight: 600;
  margin-left: 5px;

}

.card-description {
  font-weight: 500;
  color: var(--black);
}

.pricing-color {
  font-weight: 700;
  color: var(--black);
}

.pricing-color-permotion {
  font-weight: 700 !important;
  font-size: 13px !important;
  color: #D8147E;
}

.items-tag-style {
  color: var(--gray-1);
  font-weight: 600;

}

.subcat-description {
  color: var(--black);
  font-weight: 500;

}

.home-accountName {
  font-weight: 700;
  color: var(--black);
  background-color: white;
}



.css-1tkm9rf-control {
  border-radius: 26px !important;

}

.css-1hm4x9g-control {
  border-radius: 26px !important;

}

.css-1nmdiq5-menu {
  border-radius: 26px !important;

}

.css-1jqq78o-placeholder {
  margin-left: 10px;
}

.css-1xc3v61-indicatorContainer {
  color: var(--black) !important;
}

.css-1u9des2-indicatorSeparator {
  background-color: white !important;
}

.subcat-mainmenu {
  background-color: white !important;
}

.home-container {
  background-color: #F1F1F1;

}

.home-subheader {
  background-color: white !important;
}

/* .default-bg {
  background-color: #F1F1F1
} */

.white-bg {
  background-color: white !important;
}

.color-b-weight-7 {
  font-weight: 700;
  color: var(--black);
}

.color-b-weight-6 {
  font-weight: 600;
  color: var(--black);
}

.color-b-weight-5 {
  font-weight: 500;
  color: var(--black);
}

.menu-price {
  font-size: 13px;
  color: var(--black);
}

.img-add-items {
  margin-top: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  max-height: 233px;
  object-fit: cover;
}

.add-item-cross-btn {
  display: inline-block;
  height: 26px;
  width: 26px;
  position: absolute;
  top: 20px;
  right: 10px;

}

.radio-image {
  width: 20px;
}

.card-default {
  box-shadow: none;
  border: 0;
  border-bottom: 2px solid #E3E3E3;
}

.card-default-permotion {
  box-shadow: none;
  border: 0;
}

.promotion-price {
  font-size: 15px;
  font-weight: 600;
  color: var(--store-hyperlink-color);
}

.promotion-strike-off {
  font-size: 16px;
  text-decoration: line-through;
}

.cl-b-w-7 {
  color: var(--black);
  font-weight: 700;

}

.add-on-title {
  background-color: white;
}

.add-on-options {
  border-bottom: 1px solid var(--gray-1);

}

.dropdown-cart {
  /*border: 1px solid #C2C2C2;
  border-radius: 26px;
  /* height: 46px; */

}

.dropdown-add {

  height: 46px;

}

.dropdown-menu-cart {
  width: 100%;
  border: 1px solid #C2C2C2;
  border-radius: 26px;
  max-height: 300px;
  overflow-y: auto;
}


/* .dropdown-cart:focus {
  box-shadow: none !important;
  border: none !important;

} */

.custom-option-label {
  font-weight: 600;
  font-size: 14px;
  color: var(--black);
}

.custom-option-symbol {
  font-weight: 500;
  font-size: 11px;
  color: var(--gray-1);
}

.custom-option {
  border-bottom: 1.5px solid var(--gray-1);
}

.view-all-condition {
  font-size: 13px;
  font-weight: 600;
  color: var(--store-color);


}

.validation {
  color: red;
  /* text-align: start; */
  font-size: 12px;
  font-weight: 600;
}

.validation-none {
  display: none;
  font-size: 12px;
  font-weight: 600;

}

.recommended-card-title {
  font-size: 13px;
  font-weight: 600;
}

/* #react-select-2-listbox{
  background: #D8147E !important;
  z-index: 9999999999999999;

} */

.cart-menu-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 123px;
  width: 101%;
  /* min-width: 82px; */
  /* max-width: 75px; */
  border-radius: 4px;
  overflow: hidden;
  padding: 0 !important;
}

.cart-submitted-title {
  font-weight: 700;
  font-size: 16px;
}

.merchant-title {
  top: 4px;
  font-size: 20px;
  font-weight: 700;
}

.hotel_name_icon {
  display: flex;
  align-items: center;
  /* margin: 0 0 0 22px; */
}

.menu-sections.removeHeight {
  height: auto !important;
  overflow-y: auto;
}

.removeHeight {
  height: auto !important;
  overflow-y: auto;
}

.sticky-top-menu {
  position: sticky;
  top: 0px;
  padding: 10px 0px !important;
  z-index: 99999999;
}

.checkbox-wrapper input {
  appearance: auto !important;

}

.loyalityButton {
  background: white;
  border-radius: 50px;
  color: var(--store-color) !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  justify-content: center;
  width: 143px;
  /* border: 2px solid var(--store-color); */
}

.basketButton {
  background: var(--store-color);
  /* box-shadow: 0px 0px 12px 0px #00000040; */
  border-radius: 50px;
  color: white !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  text-align: center;
  padding: 10px 20px;
  width: 199px;
}

.cart-button {
  background: var(--store-color);
  border-radius: 50px;
  color: white !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  text-align: center;
  padding: 10px 0px;
  width: 100%;
  border: 2px solid var(--store-color);
  display: block
}

.cart-button-loyality {
  background: white;
  border-radius: 50px;
  color: var(--store-color) !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  text-align: center;
  padding: 10px 0px;
  width: 100%;
  border: 2px solid var(--store-color);
  display: block
}

.menu-checkbox {
  display: inline !important;
  appearance: auto !important;
  -webkit-appearance: auto !important;
  width: 24px;
  border-radius: 50%;
  border: 1px solid var(--store-color);

}

.menu-checkbox:checked {
  background-color: var(--store-color);
}

.header-collapse {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  transition: ease 0.5s;
  z-index: 999999999;
  overflow: auto;
}

.recommended-title {
  font-size: 16px;
  font-weight: 600;
}

.recommended-image {
  border-radius: 8px;
  height: 148px;
  max-height: 148px;
  object-fit: cover;

}

.recommended {
  /* padding: 15px */
}

.recommended-list {
  margin-top: 10px;
}

.txt-green-card {
  font-size: 13px !important;
  font-weight: 600 !important;
}

.green-title-cart {
  font-size: 13px !important;
  font-weight: 600 !important;
}

.txt-green-count {
  width: 28px;
  height: 28px;
  position: absolute;
  left: 65px;
  top: 13px;
  background: var(--store-color);
  border-radius: 50%;
  color: white !important;
  border: 2px solid white !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.categories-text {
  font-weight: 500;
  font-size: 14px;

}

.categories-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.categories-search {
  justify-content: flex-end;
}

.cartVerified {
  height: 45px;
  width: 45px;
}

.creditcard {
  height: 21px;
  width: 21px;
}

.add-exit-order {
  border-radius: 5px;
}

.split-dec {
  border-radius: 50%;
  border: 2px soild var(--store-color);
}

.details-title {
  color: var(--black);
  font-weight: 600;
  font-size: 16px;
}

.details-lable {
  color: var(--black) !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  text-transform: none !important;

}

.payment-title {
  font-weight: 600;
  font-size: 16px;
  color: var(--black);
}


.image-bill {
  width: 42px;
  height: 42px;
}

.order-status-text {
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.1;
}

.review-title {
  font-weight: 600;
  font-size: 18px;
}

.loyality {
  background-color: white;
  color: var(--black);
  width: 50px;
}

.add-items-title {
  font-size: 18px !important;
  font-weight: 700 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;

}

.discount p {
  color: var(--store-hyperlink-color);
  font-size: 14px;
  font-weight: 600;
}

.item-title {
  font-weight: 600;
  font-size: 18px;
  color: var(--black);
}

.filterTag-menuItem {
  font-size: 14px;
  font-weight: 600;
  color: var(--gray-1);
}

.carousel-image {
  border-radius: 20px;

}

.more-btn-perk {
  border-radius: 10px;
  border: 1.5px solid var(--store-color);
  font-size: 13px;
  font-weight: 600;
  color: var(--store-color);
  background: white;
  /* padding: 10px; */
}

.perk-image {
  /* height: 82px; */
  /* width: 82px; */
  border-radius: 6px;
  object-fit: contain;
}

.loyality-header span {
  font-weight: 600;
  font-size: 18px;
}

.bg-none {
  background: none !important;
}

.login-new {
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
}

header.add-item-header {
  overflow: inherit !important;
  position: sticky;
  top: 0;

}

.dropdown-item {
  font-weight: 500;
  font-size: 16px;
  border-bottom: 1px solid var(--gray-1);
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  width: -webkit-fill-available !important;
}

.dropdown-item-nonborder {
  font-weight: 500;
  font-size: 16px;
  border-bottom: 1px solid var(--gray-1);
  color: var(--black);
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}


.add-items_sec.custom_radio .options label:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  border: 2px solid #bababc;
  margin-right: 0px;
  position: absolute;
  right: 0;
  left: inherit !important;
  top: 50%;
  transform: translateY(-50%);
  /* border-color: #1ebdc3; */
  border-color: var(--store-color) !important;


}


.add-items_sec.custom_radio .options input:checked+label:after {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: inherit !important;
  /* background-color: #1ebdc3; */
  display: block;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  border-radius: 50%;
  background-color: var(--store-color) !important;
}

.options label {
  width: 100%;
  display: block !important;
}

.add-items_sec.custom_radio .options input:checked+label:before {
  background: transparent;
}

#react-select-2-option-7 {
  z-index: 999999999999999999999999999 !important;
}

.add-items_sec.custom_radio .options label {
  padding-left: 0px !important;

}

.price-position {
  position: absolute;
  right: 15%;
}


.menu-row.rm-overflow {
  overflow: inherit !important;
}

.searchDropDown {
  border: 1.5px solid #c2c1c1;
  padding: 8px;
  height: 44px;
  text-align: start;
  border-radius: 26px;
  width: 100%;
  height: 44px;
}


.categoriesDropDown {
  height: 44px;

}

.dropdown-search {
  border: none;
  background: white !important;
  font-family: "Montserrat";

  /* border-bottom: 2px solid var(--gray-1); */
}

.input-title {
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;

}

.input-title-center {
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;

}

.btn-radius {
  border-radius: 15px;
}

.dropdown-menu.dropdown-search {
  width: 100% !important;
  transform: translate3d(0px, 13.1429px, 0px) !important;
  border: 2px solid var(--gray-1);
  border-radius: 22px;
  background: white;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdown-filter:active {
  border: "none" !important;
  box-shadow: none !important;


}

.dropdown-filter:focus:target {
  border: "none";
}

.filter-dropdown-symbol {
  font-family: "Montserrat";
  font-size: 13px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray-1);

}

.filter-dropdown-name {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--black);
}

.bottom-margin {
  margin-bottom: 180px !important;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  box-shadow: none !important;
  border: 1px solid #C2C2C2 !important;
  color: var(--black)!important;
  border-radius: 26px 26px 26px 26px !important;
  /* border-bottom: none !important; */
}

.btn:focus {
  box-shadow: none !important;


  /* border: none !important; */
}

.dropdown-item:active {
  background: none !important;
}

.basket-title {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  /* margin-left: 10px; */
  color: var(--black);
  /* text-align: left; */
}

.hyperlink-color {
  color: var(--store-hyperlink-color);
}

.store-color {
  color: var(--store-color);
}

input[type=radio] {
  accent-color: var(--store-color);
}

.privacy-policy {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;

}

.payment-card {
  height: 55px !important;
}


.carousel .slide {
  width: 90%;
  margin-right: 5%;
  /* Adjust margin for spacing between images */
  float: left;
  overflow: hidden;
}

/* .carousel-rec .slider {
  width: 85% !important;
} */
.carousel-home .carousel .slider {
  width: 85%;

}

.carousel-body {
  border: 10px solid rgb(255, 255, 255);


}

/* Adjust the width of the next image in the array */
.carousel .carousel-slider .slide:nth-child(2) {
  width: 10%;
  margin-right: 0;
  /* Reset margin for the next image */
}

.loyality-overflow {
  overflow: inherit !important;
}

.loyality-title {
  font-family: "Montserrat" !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 13px !important;

}

.menu-loyality-title {
  font-family: "Montserrat" !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  line-height: 16px;
  letter-spacing: 0em;
}

.grey-color {
  background-color: var(--gray-1);
}

.dropdown-text {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;

}

.dropdown-search-text {
  min-width: 172px;

}

.width-90 {
  width: 90% !important;
}

.landing-header {
  padding: 15px 0px 0px;
  width: 100%;
  background: white;
}

.subCat-home {
  font-size: 15px !important;
  letter-spacing: -1px !important;
}

.item-description {
  letter-spacing: -1px !important;
}

.hide-top {
  display: none !important;
}

.letter-spacing {
  letter-spacing: -1px !important;
}

.text-width {
  overflow: hidden;
  text-overflow: ellipsis;
}

.otp_heading {
  font-weight: 600;
  font-size: 15px;
}

.info-sec-fixed {
  position: "fixed";
  overflow: "hidden"

}

.accountModal {
  overflow: hidden !important;
}

.fav-sec.bg-white {
  height: 400px;
  padding-bottom: 30px;
  overflow: auto;
}

.history-info {
  height: calc(100vh - 200px);
  padding-bottom: 80px;
  overflow: auto !important;
}

/* .card-padding{
  padding:  35px !important;

} */
.card-padding .menu-img {
  width: auto !important;
  min-width: auto !important;
}

/* .cart-sec-ms .card-padding.menu-box{
  padding-left:  15px !important;
  padding-right:  15px !important;
} */

.add-image-height .carousel-root ul li.slide div img {
  height: 250px;
  border-radius: 20px 20px 0px 0px !important;
}

.height-view {
  height: auto !important;
  margin-bottom: 180px;
  margin-top: inherit !important;

}

.img-fluid-add {
  margin-top: 10px;
  max-width: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  /* height: auto; */
  /* overflow: hidden; */
  transition: transform 0.01s ease-in;
  border-radius: 25px 25px 0px 0px;
}

.img-section {
  background-color: var(--light-grey);
  height: 230px;
  width: 100%;
  overflow: hidden;
}

/* .dropdown-menu {
  display: none;
  transition: all 0.8s ease-in-out; 
}

.dropdown.show .dropdown-menu {
  display: block;
  opacity: 1;
  transform: translate3d(0, 0, 0);
} */

.dropdown-menu {
  display: block;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-50px);
  transition: all 0.5s ease;

}

.dropdown-cart.dropDown-test {
  border: none;
}

.dropdown-cart.dropDown-test button {
  border: 1px solid #C2C2C2 !important;
  border-radius: 26px !important;
}

.dropdown-cart.dropDown-test button.btn.show {
  border: 1px solid #C2C2C2 !important;
  border-radius: 26px 26px 0px 0px !important;
  border-bottom: none !important;
}

.dropdown-menu.show {
  display: block;
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
  transition: all 0.5s ease;
  border-top: none !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  margin-top: -2px !important;
}

.dropdown-cart.open {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom: none !important;
}

.dropdown:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}

.dropdown-menu:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}

.dropdown:focus {
  outline: none !important;
  box-shadow: none !important;
}

.dropdown-menu:focus {
  outline: none !important;
  box-shadow: none !important;
}

.my-btn {
  border: 1px solid var(--gray-1) !important;
  border-radius: 26px;
}

.dropdown-toggle::after {
  display: "none" !important;
}

.test-animation {

  transition: 4s ease-in-out;

}

/* .test-animation {
  transform: 4s ease-in-out;
} */

.lodingData {

  background-color: white;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  align-items: center;
  justify-content: center;

}

.test-cont {
  margin: auto;
}

.dropDown-search-svg {
  border-top: 1px solid var(--gray-1);

}


.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* display: flex; */
  /* justify-content: flex-end;
  align-items: flex-end; */
  z-index: 999;
}

.popup-content {
  width: 100%;
  height: 100% !important;
  min-height: 100vh;
  background-color: white;
  overflow-y: auto;
  animation: slide-up 0.4s ease-in-out;
}

.popdown-content {
  animation: slide-down 0.4s ease-in-out;
}


@keyframes slide-up {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
}

.testpopUp {
  z-index: 0
}

.modal.fade {
  z-index: 99999999999;
}

.modal.fade.show {


  position: relative;
  animation: fadeInDownToUp 0.2s linear;
  /* Adjust the duration as needed */

}

@keyframes fadeInDownToUp {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

.collapse-content {
  transition: transform 0.3s ease-out;
  /* Adjust the transition duration and timing function */
  /* overflow: hidden; */
  /* height: 0; */
}

.dropdown-cart.show svg {
  transform: rotate(180deg);
}


.drop-height {
  transition: height 0.3s ease-out;
  /* Adjust the transition duration and timing function */


}

.dropDown-p {
  height: 60px;
  position: relative;
  /* border: 1px solid black ; */
  /* border-radius: 12px; */
}

/* .dropDown-search-p{
  height: 60px;
  position: relative;
  display: flex;
  width: 60px;
}
.dropDown-cat-p-expanded{
  height: 60px;
  position: relative;
  width: 270px;
}
.dropDown-search-p-expanded{
  height: 60px;
  position: relative;
  display: flex;
  width: 60px;
} */
.dropDown-categories-expand {
  position: absolute;
  height: 480px;
  width: 100%;
  border: 1px solid black;
  border-radius: 12px;
  top: 0;

}

.dropDown-categories {
  position: absolute;
  height: 50px;
  width: 100%;
  border: 1px solid black;
  border-radius: 12px;
  top: 0;
}

.dropDown-search {
  position: absolute;
  height: 50px;
  width: 100%;
  border: 1px solid black;
  border-radius: 12px;
  top: 0;
}

.dropDown-search-expand {
  position: absolute;
  height: 480px;
  width: 100%;
  border: 1px solid black;
  border-radius: 12px;
  top: 0;

}

/* .dropDown-search-categories{
position: absolute;
  height: 480px;
  width: 100%;
  border: 1px solid black ;
  border-radius: 12px;
  top:0;
} */
.dropDown-animation {
  transition: height 0.5s ease, width 0.5s ease !important;
  /* Adjust the transition duration and timing function */

}

.dropdown-menu.show.marginTop {
  margin-top: -20px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.checkbox-align {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.otp-text {
  font-size: 15px;
  font-weight: 600;
  font-family: 'Montserrat';
}

.new-customer-text {
  font-size: 15px !important;
  /* font-weight: 600; */
  font-family: 'Montserrat';
}

.overflow-h {
  overflow: hidden;

}

.overflow-a {
  overflow: auto;

}




.height-normal {
  /* display: none; */
  transition: display 0.3s ease;
  /* Transition property for opacity */

}

.height-min {
  display: none;
  /* height: 0px; */
}

.height-max {
  display: block;
  /* height: 100px; */
}

.fade-element {
  /* opacity: 0; */
  transition: opacity 0.3s ease;
  /* Transition property for opacity */
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

html.overflow-hidden {
  overflow: hidden;
}

html.overflow-default {
  overflow: auto;
}

.item-payment {
  font-size: 12px !important;
  color: var(--gray-1) !important;
  margin-left: -22px;
}

.Px-15 {
  padding-left: 12px !important;
  padding-right: 12px !important;

}

.Mx-15 {
  margin-left: 12px !important;
  margin-right: 12px !important;

}

.bill-total {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 700;



}

.billTotal-grid {
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding: 15px !important;


}

.your-payment {
  color: var(--store-hyperlink-color) !important;
  font-family: "Montserrat" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.no-payment {
  font-family: "Montserrat" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: var(--gray-1);
  margin-top: 25px;
  /* margin-bottom: 10px; */



}

.price-split {
  font-family: "Montserrat";
  font-size: 13px;
  font-style: italic;
  font-weight: 600;
  color: var(--black);

}

.custom-ammount {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 700;
  /* margin-top: 15px; */
}

.enter-ammount {

  font-family: "Montserrat";
  font-size: 13px;
  font-weight: 600;
  color: var(--gray-1);


}

.payment-info {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 700;
}

ul.w-100 li.position-relative .price-position {
  top: 0px;
}

.new_items_1 .menu-box:after {
  content: "";
  border-bottom: 2px solid #e8e8e8;
  width: calc(100% - 32px);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: block !important;
  height: 1px;
  background-color: #e8e8e8 !important;
}

.border-top-bottom {
  border-top: 2px solid #E3E3E3;
  border-bottom: 2px solid #E3E3E3;

}

.card-number {
  border: 2px solid #C2C2C2;
  border-radius: 10px;
  padding: 8px 15px 10px
}

.card-number .form-control-payment {
  border: none;
  padding: 0px;
  /* border-radius: 10px; */
}

.payment-header {
  font-size: 16px !important;
  font-weight: 700 !important;

}

.button-text {
  font-size: 15px !important;
  font-weight: 700 !important;


}

.jjj {
  transform: rotate(180deg);
}

.container {
  /* height: 100px; */
  transition: opacity 0.5s ease, height 0.5s ease;
}

.hidden {
  /* display: none !important; */
  height: 0px !important;
  opacity: 0;
  /* pointer-events: none; Disable interactions on hidden element */
}

html,
body {
  scroll-behavior: smooth;
  transition: transform 5s ease-in-out;
  /* Adjust duration and easing as needed */
}

.modifier-options {
  font-family: "Montserrat";
  font-size: 14px !important;
  font-weight: 500 !important;
}

.tt-p {
  padding-top: 25px !important;
  padding-bottom: 5px !important;
}

.t-p {
  padding-top: 40px !important;
}
.tt-p-override {
  padding-top: 20px !important;


}
/* .tt-bottom-override {
  padding-bottom: 8px !important;
} */

.t-b {
  padding-bottom: 15px !important;
}

.tt-m {
  margin-top: 25px !important;
  margin-bottom: 5px !important;
}

.paddingLoyality {
  padding-top: 24px !important;
  padding-bottom: 15px !important;

}

.back-padding {
  position: relative !important;
  padding-left: 8px !important;
}

.back-padding-1 {
  position: relative !important;
  padding-left: 5px !important;
}

.extra-padding {
  padding-top: 28px !important;
}

.dropdown-menu li:last-child a {
  border-bottom: none;
}

.link-box.active .text-border-account:after {
  content: "";
  display: inline-block;
  width: 70%;
  height: 5px;
  background: var(--store-color);
  bottom: 10px;
  position: absolute;
  left: 0px;
}

.link-box.active .text-border-perk:after {
  content: "";
  display: inline-block;
  width: 46%;
  height: 5px;
  background: var(--store-color);
  bottom: 10px;
  position: absolute;
  left: 0px;
}

.link-box.active .text-border-history:after {
  content: "";
  display: inline-block;
  width: 58%;
  height: 5px;
  background: var(--store-color);
  bottom: 10px;
  position: absolute;
  left: 0px;
}

.strike-off {
  text-decoration: line-through;
}

.extra-space-bill {
  margin-left: -3px;
}

.hide-radio .options label::before {
  content: '';
  display: none !important;
}

.hide-radio .options label::after {
  content: '';
  display: none !important;
}

/* .non-loyality-login{
  position: absolute;
  bottom: 10px;
  left: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
} */

.login-crm-title {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;


}

.login-Crm-heading {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;


}



.callServer-modal {
  /* display: none; Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 9999;
  /* Sit on top */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(255, 255, 255);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal Content/Box */
.modal-content-callserver {

  background-color: #fefefe;
  padding: 20px;
  border: 2px solid #ADADAD;
  width: 171px;
  height: 190px;
  border-radius: 21px;
}

.server-notified {
  font-family: Montserrat;
  font-size: 16px !important;
  font-weight: 700;
}

.cancle-request {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  color: var(--store-color);
}

.close-callServer {
  top: 10px;
  right: 15px
}

.modal-fixed{

  position: fixed !important;
  top: 50%;
  left: 50%;
  z-index: 9999999 ;
  display: block;
transform: translate(-50%,-50%);



}